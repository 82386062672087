import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { CONFIRMED, PALO_BLUE, PATHS, SUBMITTED, BLOCKED } from '../../constants'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import { Menu,
    Typography,
 } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { authenticationService } from '../../Utilities/authenticationService'
import { useStickyState } from '../../Utilities/useStickyState'
import {
    AccountCircleOutlined,
    EventNoteOutlined,
    HomeOutlined,
    AutoGraphOutlined,
    SendOutlined,
    EditOutlined,
    ArrowDropDown,
    SearchOutlined,
    CalendarToday,
    KeyboardArrowLeft,
    KeyboardArrowRight
} from '@mui/icons-material'
import { SwipeableDrawer, Drawer, Divider, List, BottomNavigation, BottomNavigationAction } from '@mui/material'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import { isMobile } from '../../Utilities/isMobile'

const drawerWidth = 240

const theme = createTheme({
    palette: {
        paloBlue: {
            main: PALO_BLUE,
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            contrastText: '#fff'
        },
        submitted: {
            main: SUBMITTED,
            contrastText: '#fff'
        },
        confirmed: {
            main: CONFIRMED,
            contrastText: '#fff'
        },
        blocked: {
            main: BLOCKED,
            contrastText: '#fff'
        }
    },
    components: {
        MuiAutocomplete: {
            defaultProps: {
                noOptionsText: '該当なし'
            }
        }
    }
})

const DrawerItem = ({ toggleDrawer, path, text, icon }) => {
    return (
        <NavLink to={path} aria-label={text} onClick={() => toggleDrawer(false)}>
            <MenuItem>
                <ListItemIcon sx={{ mr: '1rem' }}>{icon}</ListItemIcon>
                <ListItemText primary={text} />
            </MenuItem>
        </NavLink>
    )
}

const Layout = ({ children }) => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [desktopMenuOpen, setDesktopMenuOpen] = useStickyState(true, 'frederickDesktopMenuOpen')
    const { isManager, canEditShift, setUserEmployments, isAdmin, resetAppsStates } = useContext(EmploymentsContext)
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElForManageMenu, setAnchorElForManageMenu] = useState(null)
    const adminOpen = Boolean(anchorEl)
    const managerOpen = Boolean(anchorElForManageMenu)
    const [prevScrollPos, setPrevScrollPos] = useState(0)
    const [visible, setVisible] = useState(true)
    const isLoggedIn = localStorage.getItem('currentUser') !== null
    const calendarYearMonth = JSON.parse(localStorage.getItem('frederickSelectedYearMonth'))
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset
        setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)
        setPrevScrollPos(currentScrollPos)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [prevScrollPos, visible])

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setAnchorElForManageMenu(null)
    }

    const toggleDrawer = action => {
        setOpen(action)
        setDesktopMenuOpen(action)
    }

    const handleLogout = () => {
        setUserEmployments([])
        resetAppsStates()
        localStorage.clear()
        authenticationService.logout()
        history.push(PATHS.login)
    }

    const toolbar = (
        <>
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <img src="/company-logo.png" alt="company-logo" style={{ width: drawerWidth, height: 'auto' }} onClick={() => history.push(PATHS.home)}/>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <SwipeableDrawer
                id="drawer"
                anchor="left"
                open={open}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
            >
                <List>
                    <DrawerItem toggleDrawer={toggleDrawer} path={PATHS.home} icon={<HomeOutlined />} text="ホーム" />
                </List>

                <Divider orientation="horizontal" flexItem />
                <List>
                    {(isManager || canEditShift) && (
                        <DrawerItem
                            toggleDrawer={toggleDrawer}
                            path={PATHS.managerStores + localStorage.selected_emp}
                            icon={<EditOutlined />}
                            text="シフト表を作成する"
                        />
                    )}
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.check}
                        icon={<EventNoteOutlined />}
                        text="シフトを確認する"
                    />
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.stores + localStorage.selected_emp}
                        icon={<SendOutlined />}
                        text="希望シフトを提出する"
                    />
                </List>
                <Divider orientation="horizontal" flexItem />

                <List>
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.userSettings}
                        icon={<AccountCircleOutlined />}
                        text="あなたのアカウント"
                    />
                    {isManager && (
                        <DrawerItem
                            toggleDrawer={toggleDrawer}
                            path={PATHS.managerSettings}
                            icon={<SettingsIcon />}
                            text="設定"
                        />
                    )}
                    <ButtonWithConfirmPrompt
                        component={
                            <MenuItem aria-label="logout">
                                <ListItemIcon sx={{ mr: '1rem' }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="ログアウト" />
                            </MenuItem>
                        }
                        title="ログアウト"
                        content="ログアウトしてよろしいですか？"
                        confirmText="ログアウト"
                        handleConfirm={() => {
                            toggleDrawer(false)
                            handleLogout()
                        }}
                    />
                </List>
            </SwipeableDrawer>
        </>
    )

    const desktopMenu = (
        <>
            <Drawer variant="permanent" open={desktopMenuOpen}>   
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100vh',
                    width: desktopMenuOpen ? '100%' : '3.5rem'
                }}>
                    <List>
                        {desktopMenuOpen ? <img src="/company-logo.png" alt="company-logo" style={{ margin: '.5rem', width: 230, height: 'auto' }} onClick={() => history.push(PATHS.home)}/> 
                        : <img src="/company-logo-collasped.png" alt="company-logo" style={{ margin: '.5rem', width: '2.5rem', height: 'auto' }} onClick={() => history.push(PATHS.home)}/>}


                        <MenuItem
                            onClick={() => history.push(PATHS.home)}
                            sx={{ fontSize: '1rem' }}
                        >
                            <HomeOutlined sx={{ mr: '1rem' }} />
                            {desktopMenuOpen && <Typography>ホーム</Typography>}
                        </MenuItem>
                        <MenuItem
                            onClick={() => history.push(PATHS.shiftManagement.replace(':employmentId', localStorage.selected_emp).replace(':calendarYearMonth', calendarYearMonth).replace(':tabValue', 'check'))}
                            sx={{ fontSize: '1rem' }}
                        >
                            <EventNoteOutlined sx={{ mr: '1rem' }} />
                            {desktopMenuOpen && <Typography>シフト管理</Typography>}
                        </MenuItem>
                        {isManager && (<>
                            <MenuItem onClick={event => setAnchorElForManageMenu(event.currentTarget)}>
                                <SupervisedUserCircleOutlinedIcon sx={{ mr: '1rem' }}/>
                                {desktopMenuOpen && <><Typography>ユーザー・店舗情報</Typography><ArrowDropDown sx={{ ml: '1rem' }}/> </>}
                            </MenuItem>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorElForManageMenu}
                                open={managerOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.userManagementStores)
                                    }
                                }>
                                    ユーザー管理
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.storeManagement)
                                    }}
                                >
                                    店舗管理
                                </MenuItem>
                            </Menu>
                        </>)}
                        {isManager && (
                            <MenuItem
                                onClick={() => history.push(PATHS.feedbackManagement.replace(':employmentId', localStorage.selected_emp).replace(':calendarYearMonth', calendarYearMonth))}
                                sx={{ fontSize: '1rem' }}
                            >
                                <AutoGraphOutlined sx={{ mr: '1rem' }} />
                                {desktopMenuOpen && <Typography>総合評価グラフ</Typography>}
                            </MenuItem>
                        )}
                        {isAdmin && (
                            <>
                                <MenuItem onClick={handleClick}>
                                    <VpnKeyOutlinedIcon sx={{ mr: '1rem' }}/>
                                    {desktopMenuOpen && <><Typography>管理者</Typography><ArrowDropDown sx={{ ml: '1rem' }}/></>}
                                </MenuItem>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={adminOpen}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button'
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleClose()
                                            history.push(PATHS.adminUserManagements)
                                        }}
                                    >
                                        ユーザー管理
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleClose()
                                            history.push(PATHS.adminStoreManagements)
                                        }}
                                    >
                                        店舗管理
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleClose()
                                            history.push(PATHS.adminHLG)
                                        }}
                                    >
                                        マスタデータ管理
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </List>
                    <List sx={{ pb: 0 }}>
                        <MenuItem
                            onClick={() => history.push(PATHS.userSettings)}
                            sx={{ fontSize: '1rem' }}
                        >
                            <AccountCircleOutlined sx={{ mr: '1rem' }} />
                            {desktopMenuOpen && <Typography>あなたのアカウント</Typography>}
                        </MenuItem>
                        <ButtonWithConfirmPrompt
                            component={
                                <MenuItem aria-label="logout">
                                    <LogoutIcon sx={{ mr: '1rem' }}/>
                                    {desktopMenuOpen && <Typography>ログアウト</Typography>}
                                </MenuItem>
                            }
                            title="ログアウト"
                            content="ログアウトしてよろしいですか？"
                            confirmText="ログアウト"
                            handleConfirm={() => {
                                toggleDrawer(false)
                                handleLogout()
                            }}
                        />
                        <Box sx={{ backgroundColor: '#1976D2', mb: 0, display: 'flex', justifyContent: 'space-evenly' }}>
                            {desktopMenuOpen ?
                                <IconButton
                                    onClick={() => setDesktopMenuOpen(false)}
                                >
                                    <KeyboardArrowLeft sx={{color: 'white'}}/>
                                </IconButton>
                                :
                                <IconButton
                                    onClick={() => setDesktopMenuOpen(true)}
                                >
                                    <KeyboardArrowRight sx={{color: 'white'}}/>
                                </IconButton>
                            }
                        </Box>
                    </List>
                </Box>
            </Drawer>
        </>
    )
    
    const mobileBottomNavigation = <>
        {localStorage.getItem('selected_emp') && 
            <Box sx={{ width: '100vw', overflow: 'hidden', position: 'fixed', bottom: 0, zIndex: 1300, display: visible ? 'block' : 'none' }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        history.push(newValue)
                        setValue(newValue);
                    }}
                    sx={{ width: '100%', maxWidth: '100%', position: 'fixed', bottom: 0, backgroundColor: 'white'}}
                >
                    <BottomNavigationAction label="ホーム" icon={<HomeOutlined />} value={PATHS.home}/>
                    <BottomNavigationAction label="シフト" icon={<CalendarToday />} value={PATHS.shiftManagement.replace(':employmentId', localStorage.selected_emp).replace(':calendarYearMonth', calendarYearMonth).replace(':tabValue', 'check')}/>
                    {(isManager || isAdmin) && <BottomNavigationAction label="検索" icon={<SearchOutlined />} value={PATHS.managerSettings}/>}
                    {/* {isAdmin && <BottomNavigationAction label="管理者" icon={<VpnKeyOutlined />} value={PATHS.adminUserManagements}/>} */}
                    <BottomNavigationAction
                        label="アカウント"
                        icon={<AccountCircleOutlined />}
                        sx={{
                            '& .MuiBottomNavigationAction-label': {
                                fontSize: '0.75rem',
                                whiteSpace: 'nowrap',
                            }
                        }}
                        value={PATHS.userSettings}
                    />
                </BottomNavigation>
            </Box>
        }
    </>

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1 }}>
                    {!isMobile() ? (
                        localStorage.getItem('currentUser') == null ? null : (
                            desktopMenu
                        )
                    ) : (
                        <AppBar position="static" color="">
                            {localStorage.getItem('currentUser') == null ? null : toolbar}
                            {mobileBottomNavigation}
                        </AppBar>
                    )}
                    <div style={{marginLeft: isMobile() ? 0 : isLoggedIn ? desktopMenuOpen ? '250px' : '3rem' : 0}}>
                        {children}
                    </div>
            </Box>
        </ThemeProvider>
    )
}

export default Layout
