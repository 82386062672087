import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, TextField, Stack, CircularProgress, Checkbox, FormControlLabel, Card, Typography, Divider, Alert, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { ROLE_MAPPINGS, PART_FULLTIME_MAPPINGS, OPEN_CLOSE_MAPPINGS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { validateHiraganaKatakana, validateKanjiHiraganaKatakana, validateEmail, validatePhone } from '../../Utilities/fieldValidators'
import EditPassword from './UserEditFlows/EditPassword'

const UserSettings = () => {
    const { fetchAndSetEmployments } = useContext(EmploymentsContext)
    const [info, setInfo] = useState()
    const [errors, setErrors] = useState({})
    const [isUpdated, setIsUpdated] = useState(false)
    const [openPasswordModal, setOpenPasswordModal] = useState(false)

    const fetch = async () => {
        const userInfo = await AxiosWithAuth.get('/users/me')
        const userEmploymentsInfo = await AxiosWithAuth.get('/employments/')
        setInfo({
            ...userEmploymentsInfo?.data[0],
            id: userInfo.data.id,
            employmentId: userEmploymentsInfo?.data[0]?.id,
            storeNames: userEmploymentsInfo?.data.map(e => ` ${e.store.name}`),
            eType: PART_FULLTIME_MAPPINGS[userInfo.data?.e_type],
            role: ROLE_MAPPINGS[userEmploymentsInfo?.data[0]?.role],
            openCloseDuty: OPEN_CLOSE_MAPPINGS[userEmploymentsInfo?.data[0]?.open_close_duty],
            ...userInfo.data
        })
    }

    const handleReturn = () => {
        fetch()
        fetchAndSetEmployments()
        setIsUpdated(true)
        setOpenPasswordModal(false)
    }
    const handleSave = () => {
        if (Object.values(errors).some(e => e)) {
            return
        }
        AxiosWithAuth.put('/users/' + info.id, {
            name_last: info.name_last,
            name_first: info.name_first,
            kanji_last: info.kanji_last,
            kanji_first: info.kanji_first,
            name_middle: info.name_middle,
            nickname: info.nickname,
            email: info.email,
            phone: info.phone,
        })
            .then(res => {
                res.status === 200 && handleReturn()
            })
            .catch(error => {
                const errors = {}
                for (const [key, value] of Object.entries(error?.response?.data)) {
                    errors[key] = value.join(' ')
                }
                setErrors(errors)
            })
    }
    useEffect(() => {
        fetch()
    }, [])

    if (!info) {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }

    const getTextField = (label, key, disable=false) => {
        const validators = {
            name_last: validateHiraganaKatakana,
            name_first: validateHiraganaKatakana,
            kanji_last: validateKanjiHiraganaKatakana,
            kanji_first: validateKanjiHiraganaKatakana,
            name_middle: validateHiraganaKatakana,
            nickname: validateHiraganaKatakana,
            email: validateEmail,
            phone: validatePhone,
        }
        const errorText = {
            name_last: '全角ひらがな・全角カタカナ',
            name_first: '全角ひらがな・全角カタカナ',
            kanji_last: '全角ひらがな・全角カタカナ',
            kanji_first: '全角ひらがな・全角カタカナ',
            name_middle: '全角ひらがな・全角カタカナ',
            nickname: '全角ひらがな・全角カタカナ',
            email: '半角英数字',
            phone: '半角数字',
        }
        return <TextField
            label={label}
            value={info[key]}
            onChange={e => {
                if (!validators[key](e.target.value)) {
                    setErrors({ ...errors, [key]: errorText[key] })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                setInfo({ ...info, [key]: e.target.value })            
            }}
            sx={{ width: '50%' }}
            error={errors[key]}
            helperText={errors[key]}
            disabled={disable}
        />
    }
    return (
        <PageWithTitleLayout title="あなたのアカウント">
            {isUpdated && <Alert
                severity="success"
                sx={{ mx: 6, mt: 2, mb: 3 }}
                action={<IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setIsUpdated(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                </IconButton>}
            >
                変更が保存されました
                </Alert>
            }   
            <Card sx={{ p: 2, width: 'calc(100vw - 360px)', margin: '2rem' }} >
                <Typography variant="h6" sx={{ mb: 2 }}>基本情報</Typography>
                <Stack spacing={2} sx={{ flexWrap: 'wrap'}}>
                    <Stack direction="row" spacing={2}>
                        {getTextField('せい(かな)', 'name_last')}
                        {getTextField('めい(かな)', 'name_first')}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('姓(漢字)', 'kanji_last')}
                        {getTextField('名(漢字)', 'kanji_first')}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('ミドルネーム', 'name_middle')}
                        {getTextField('ニックネーム', 'nickname')}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('メールアドレス', 'email')}
                        {getTextField('電話番号', 'phone')}
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Button
                            onClick={() => setOpenPasswordModal(true)}
                        >
                            パスワードを変更する
                        </Button>
                    </Stack>
                    <Stack>
                        {getTextField('店舗情報', 'storeNames', true)}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('社員コード', 'emp_cd', true)}
                        {getTextField('雇用形態', 'eType', true)}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('勤務タイプ', 'role', true)}
                        {getTextField('開閉担当者', 'openCloseDuty', true)}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {getTextField('ランク', 'rank', true)}
                        {getTextField('時給', 'hourly_wage', true)}
                    </Stack>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled
                                style={{ marginLeft: '-0.75rem' }}
                                checked={info.view_only}
                            />
                        }
                        label="正社員(間接)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ marginLeft: '-0.75rem' }}
                                checked={info.is_student}
                                disabled
                            />
                        }
                        label="高校生"
                    />
                </Stack>
            </Card>
            <Divider sx={{ mt: 2 }} />
            <Box>
                <Button variant='contained' sx={{ mx: 6, mt: 2, mb: 2 }} onClick={handleSave}>
                    変更して保存する
                </Button>
            </Box>
            <EditPassword
                open={openPasswordModal}
                handleClose={() => setOpenPasswordModal(false)}
                handleReturn={handleReturn}
                propsId={info.id}
                emp_cd={info.emp_cd}
            />

        </PageWithTitleLayout>
    )
}

export default UserSettings
