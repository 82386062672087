import React from 'react'
import { List, ListItem, ListItemIcon } from '@mui/material'
import {
    KeyboardArrowRight,
    PeopleAltOutlined,
    StorefrontOutlined
} from '@mui/icons-material'
import { PATHS } from '../../constants'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'

const Home = ({ history }) => {
    return (
        <PageWithTitleLayout title="設定">
            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <List>
                    <ListItem
                        key="userManagement"
                        onClick={() => history.push(PATHS.userManagementStores)}
                    >
                        <ListItemIcon>
                            <PeopleAltOutlined />
                        </ListItemIcon>
                        ユーザー管理
                        <KeyboardArrowRight sx={{ ml: 'auto' }} />
                    </ListItem>
                    <ListItem
                        key="storeManagement"
                        onClick={() => history.push(PATHS.storeManagement)}
                    >
                        <ListItemIcon>
                            <StorefrontOutlined />
                        </ListItemIcon>
                        店舗管理
                        <KeyboardArrowRight sx={{ ml: 'auto' }} />
                    </ListItem>
                </List>
            </div>
        </PageWithTitleLayout>
    )
}

export default Home
