import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Badge,
    Button,
    Divider,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogTitle,
    CircularProgress
} from '@mui/material'
import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, EventAvailableOutlined, SwapVert } from '@mui/icons-material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import { Box } from '@mui/system'
import { isMobile } from '../../Utilities/isMobile'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'
import moment from 'moment'

const SubmittedEmployees = ({ history, setTabValue }) => {
    const { employmentId, calendarYearMonth } = useParams()
    const { isManager } = useContext(EmploymentsContext)
    const [year, month] = calendarYearMonth.split('-')
    const [employments, setEmployments] = useState()
    const [store, setStore] = useState()
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [orderingMode, setOrderingMode] = useState(false)
    const [undoDialog, setUndoDialog] = useState(false)
    const [selectedEmp, setSelectedEmp] = useState()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        AxiosWithAuth.get('/calendars/dailyShifts', {
            params: {
                employment_id: employmentId,
                date: calendarYearMonth + '-1'
            }
        })
            .then(res => {
                setMessage('')
                setEmployments(
                    res?.data.employments.sort((a, b) => {
                        if (!a.order && !b.order) {
                            return parseInt(a.user.emp_cd) - parseInt(b.user.emp_cd)
                        }
                        return a.order - b.order
                    })
                )
                setStore(res?.data.store)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setMessage('選択した店舗の店長ではありません。')
                    setEmployments([])
                }
            })
            .finally(() => setLoading(false))
    }, [employmentId, undoDialog])

    const closeShift = () => {
        AxiosWithAuth.post(`/stores/${store.seg_code}/close_shift`, {
            store: { month: calendarYearMonth }
        }).then(res => {
            if (res.status === 200) {
                setSnackbarMessage('シフトの提出を締め切りました。')
                setStore(res.data)
            }
        })
    }

    const openShift = () => {
        AxiosWithAuth.post(`/stores/${store.seg_code}/open_shift`, {
            store: { month: calendarYearMonth }
        }).then(res => {
            if (res.status === 200) {
                setSnackbarMessage('シフトの提出を再度受け付けました。')
                setStore(res.data)
            }
        })
    }

    const handleSwap = (index, direction) => {
        const current = { ...employments[index] }
        const newemployments = [...employments]
        if (direction === 'up' && index > 0) {
            newemployments[index - 1] = { ...current }
            newemployments[index - 1].order = employments[index - 1].order
            newemployments[index] = employments[index - 1]
            newemployments[index].order = current.order
        } else if (direction === 'down' && index < employments.length - 1) {
            newemployments[index + 1] = { ...current }
            newemployments[index + 1].order = employments[index + 1].order
            newemployments[index] = employments[index + 1]
            newemployments[index].order = current.order
        }

        setEmployments(newemployments)
        newemployments.forEach((employment, index) => {
            AxiosWithAuth.put('/employments/' + employment.id, {
                order: index + 1
            })
        })
    }
    const undoSubmit = () => {
        AxiosWithAuth.put('/undoSubmit', {
            employment_id: selectedEmp.id,
            date: calendarYearMonth + '-01'
        }).then(() => {
            setUndoDialog(false)
        })
    }

    const handleDragStart = e => {
        e.dataTransfer.setData('text/plain', e.target.id)
    }

    const handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()
        const data = e.dataTransfer.getData('text/plain')
        const draggedIndex = data
        const droppedIndex = parseInt(e.currentTarget.id)
        const after = Math.round(e.nativeEvent.layerY / 65)
        setEmployments(prev => {
            let newEmployments = [...prev]
            const draggedEmp = prev[draggedIndex]
            newEmployments = newEmployments.filter(emp => emp.id !== draggedEmp.id)
            newEmployments.splice(droppedIndex + after, 0, draggedEmp)
            newEmployments.forEach((emp, index) => {
                const newOrder = index + 1
                if (emp.order !== newOrder) {
                    emp.order = newOrder
                    AxiosWithAuth.put('/employments/' + emp.id, {
                        order: newOrder
                    })
                }
            })
            return newEmployments
        })
    }

    function allowDrop(e) {
        e.preventDefault()
    }
    const ClosingShiftSubmittion = () => <ButtonWithConfirmPrompt
        component={
            <Button
                disabled={store?.closed_months?.includes(calendarYearMonth) || !isManager}
                variant="outlined"
                color="paloBlue"
                style={{ margin: isMobile() ? 'auto 1rem 1rem 1rem' : '0 .5rem 0 0', width: isMobile() && '-webkit-fill-available' }}
                startIcon={<EventAvailableOutlined />}
            >
                シフト提出を締め切る
            </Button>
        }
        title="シフトの提出を締め切ります。"
        content="締め切ると従業員はシフト提出ができなくなります。よろしいですか？"
        confirmText="締め切る"
        handleConfirm={closeShift}
    />

    const AcceptShiftSubmittionAgain = () => <ButtonWithConfirmPrompt
        component={
            <Button
                variant="outlined"
                color="paloBlue"
                style={{ margin: isMobile() ? 'auto 1rem 1rem 1rem' : '0 .5rem 0 0', width: isMobile() && '-webkit-fill-available' }}
                startIcon={<EventAvailableOutlined />}
            >
                シフト提出を再度受け付ける
            </Button>
        }
        title="シフト提出を再度受け付けます"
        content="再度受け付けると従業員はシフト提出ができるようになります。よろしいですか？"
        confirmText="受け付ける"
        handleConfirm={openShift}
    />
    const CreateShiftButton = () => <Button
        onClick={() => {
            history.push(window.location.pathname.replace('status', 'create'));
            setTabValue('create')   
        }}
        variant="contained"
        color="paloBlue"
        style={{ width: isMobile() && '-webkit-fill-available', margin: isMobile() ? '0 1rem 2rem 1rem' : '0 .5rem 0 0' }}
    >
        シフトを作成する
    </Button>
    const DoneOrderingButton = () => <Button
        variant="contained"
        color="paloBlue"
        style={{ width: '-webkit-fill-available' }}
        sx={{ mx: isMobile() ? '1rem' : '35%', mb: '2rem' }}
        onClick={() => setOrderingMode(false)}
    >
        完了
    </Button>

    return (<>
            <div>
                <Snackbar
                    open={snackbarMessage.length}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarMessage('')}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSnackbarMessage('')}>{snackbarMessage}</Alert>
                </Snackbar>
                <Alert severity="error" sx={{ display: message.length ? 'block' : 'none' }}>
                    {message}
                </Alert>
                {!orderingMode ? (
                    <Box sx={{ height: '2rem', display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{ ml: '1rem', mt: '.5rem' }}>{moment(calendarYearMonth).format('YYYY年M月')}</Typography>
                        <Box>
                            {isManager && (
                                <Button
                                    variant="outlined"
                                    color="paloBlue"
                                    sx={{ marginRight: '.5rem' }}
                                    startIcon={<SwapVert />}
                                    onClick={() => setOrderingMode(true)}
                                >
                                    並び替え
                                </Button>
                            )}
                            {!isMobile() ? <>
                                {!store?.closed_months?.includes(calendarYearMonth) ? (
                                    ClosingShiftSubmittion()
                                ) : (
                                    AcceptShiftSubmittionAgain()
                                )}
                                {CreateShiftButton()}
                            </> : null}
                        </Box>
                    </Box>
                ) : (<>
                    <Typography sx={{ marginTop: '1rem' }}>
                        ドラッグ＆ドロップで表示順の並べ替えが可能です
                    </Typography>
                    {DoneOrderingButton()}
                </>
                )}
                {loading ? (
                    <CircularProgress />
                ) : employments ? (
                    <React.Fragment>
                        <List sx={{ mx: '1rem', mb: '2rem' }}>
                            <ListItem sx={{ height: '4rem' }}>
                                <ListItemText sx={{ flex: '1 1 30%' }} primary="名前" />
                                <ListItemText sx={{ flex: '1 1 30%' }} primary="社員番号" />
                                <ListItemText sx={{ flex: '1 1 30%' }} primary="ステータス" />
                            </ListItem>
                            <Divider sx={{ mx: '-1rem' }} />
                            {employments.map((e, index) => {
                                return (
                                    <div onDropCapture={handleDrop} id={index} key={e.user.emp_cd}>
                                        <ListItem
                                            sx={{ height: '4rem' }}
                                            draggable={orderingMode}
                                            onDragStart={handleDragStart}
                                            onDragOver={allowDrop}
                                            id={index}
                                        >
                                            <ListItemText sx={{ flex: '1 1 30%' }} primary={displayNameWithSpace(e.user)} />
                                            <ListItemText sx={{ flex: '1 1 30%' }} primary={e.user.emp_cd} />
                                            {orderingMode ? (
                                                <>
                                                    <ArrowCircleUpOutlined
                                                        sx={{ mr: '.5rem' }}
                                                        onClick={() => {
                                                            handleSwap(index, 'up')
                                                        }}
                                                    />
                                                    <ArrowCircleDownOutlined
                                                        onClick={() => {
                                                            handleSwap(index, 'down')
                                                        }}
                                                    />
                                                </>
                                            ) : e.submitted?.find(
                                                  e => e === `${year}-${String(month).length > 1 ? month : '0' + month}`
                                            ) ? (<ListItemText sx={{ flex: '1 1 30%' }}>
                                                    <Badge
                                                        onClick={() => {
                                                            setUndoDialog(true)
                                                            setSelectedEmp(e)
                                                        }}
                                                        badgeContent="提出済み"
                                                        color="success"
                                                        sx={{ width: '4rem', ml: '-2rem' }}
                                                    />
                                                </ListItemText>
                                            ) : (<ListItemText sx={{ flex: '1 1 30%' }}>
                                                    <Badge
                                                        badgeContent="未提出"
                                                        color="error"
                                                        sx={{ width: '4rem', ml: '-2rem' }}
                                                    />
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                        <Divider sx={{ mx: '-1rem' }} />
                                    </div>
                                )
                            })}
                        </List>
                    </React.Fragment>
                ) : null}
            </div>
            {undoDialog && (
                <Dialog open={undoDialog} onClose={() => setUndoDialog(false)}>
                    <DialogTitle>提出を取り消しますか？</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setUndoDialog(false)}>キャンセル</Button>
                        <Button
                            onClick={() => {
                                undoSubmit()
                            }}
                        >
                            取り消す
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {!orderingMode ? (
                <>
                    {isMobile() ? <>
                        {!store?.closed_months?.includes(calendarYearMonth) ? (
                            ClosingShiftSubmittion()
                        ) : (
                            AcceptShiftSubmittionAgain()
                        )}

                        {CreateShiftButton()}
                    </> : null}
                </>
            ) : (
                DoneOrderingButton()
            )}
        </>
    )
}

export default SubmittedEmployees
