import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ContentCopyOutlined, EditOutlined, CheckCircleOutline } from '@mui/icons-material'
import {
    Typography,
    Select,
    CircularProgress,
    InputLabel,
    MenuItem,
    TextField,
    Button,
    Alert,
    IconButton,
    Stack,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Input,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { WEEKDAY_MAPPINGS, PATHS } from '../../constants'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { AddOutlined, Delete, RestoreFromTrash } from '@mui/icons-material'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { isMobile } from '../../Utilities/isMobile'
import EditStoreName from './EditFlows/EditStoreName'
import EditSegCode from './EditFlows/EditSegCode'
import { HlgColumns } from '../../constants'

const initialStoreHours = {
    monday: { open_time: '', close_time: '' },
    tuesday: { open_time: '', close_time: '' },
    wednesday: { open_time: '', close_time: '' },
    thursday: { open_time: '', close_time: '' },
    friday: { open_time: '', close_time: '' },
    saturday: { open_time: '', close_time: '' },
    sunday: { open_time: '', close_time: '' }
}

const EditStore = ({ history }) => {
    const { fetchAndSetEmployments, isAdmin } = useContext(EmploymentsContext)
    const { segCode } = useParams()
    const [store, setStore] = useState()
    const [storeTypes, setStoreTypes] = useState([])
    const [selectedStoreTypeId, setSelectedStoreTypeId] = useState(null)
    const [storeHours, setStoreHours] = useState(initialStoreHours)
    const [irregularStoreHours, setIrregularStoreHours] = useState([])
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
    const [irregularStoreHourError, setIrregularStoreHourError] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [revenueStaffingRows, setRevenueStaffingRows] = useState({})
    const [viewNightStaffing, setViewNightStaffing] = useState(false)
    const [errorRev, setErrorRev] = useState({})
    const [nightStartError, setNightStartError] = useState(false)
    const [newStaffinReqs, setNewStaffinReqs] = useState({})
    const [desktop, setDesktop] = useState(false)
    const [hlgError, setHlgError] = useState(false)
    const [managerPresence, setManagerPresence] = useState(true)
    const [storeNameEdit, setStoreNameEdit] = useState(false)
    const [segCodeEdit, setSegCodeEdit] = useState(false)
    const [nightStartSaving, setNightStartSaving] = useState(false)

    const saveStore = async () => {
        setSaveButtonDisabled(true)

        const rev_responses = []
        const errors = {}
        for (const key in newStaffinReqs) {
            const rev = newStaffinReqs[key]
            const res = await AxiosWithAuth.post('/revenue_staffings', { revenue_staffing: rev }).catch(() => {
                errors[rev.hourly_rev_high + rev.shift] = true
            })
            rev_responses.push(res)
        }
        if (Object.keys(errors).length > 0) {
            setSaveButtonDisabled(false)
            setConfirmationOpen(false)
            setErrorRev(errors)
            return
        }

        let filteredStoreHours = Object.entries(storeHours)
        filteredStoreHours = filteredStoreHours.filter(([, value]) => {
            return value.open_time !== '' && value.close_time !== ''
        })
        filteredStoreHours = Object.fromEntries(filteredStoreHours)
        const filteredStoreHoursArray = Object.entries(filteredStoreHours).map(([key, value]) => {
            return {
                id: value.id,
                day: key,
                open_time: value.open_time,
                close_time: value.close_time,
                opening_staff: value.opening_staff,
                closing_staff: value.closing_staff
            }
        })
        const irregularStoreHoursArray = irregularStoreHours
            .filter(
                sh => sh.open_time !== '' && sh.close_time !== '' && sh.valid_from !== '' && sh.valid_through !== ''
            )
            .map(
                ({
                    open_time,
                    close_time,
                    opening_staff,
                    closing_staff,
                    day,
                    valid_from,
                    valid_through,
                    id,
                    _destroy
                }) => {
                    return {
                        open_time,
                        close_time,
                        opening_staff,
                        closing_staff,
                        day,
                        valid_from,
                        valid_through,
                        id,
                        _destroy
                    }
                }
            )
        const storeHoursParams = [...filteredStoreHoursArray, ...irregularStoreHoursArray]
        const storeParams = {
            store: { store_type_id: selectedStoreTypeId, store_hours_attributes: storeHoursParams }
        }

        AxiosWithAuth.put(`/stores/${segCode}`, storeParams)
            .then(() => {
                setSaveButtonDisabled(false)
                fetchAndSetEmployments()
                history.push({
                    pathname: PATHS.storeManagement,
                    message: `${store.name} の情報を更新しました。`
                })
            })
            .catch(() => {
                setSaveButtonDisabled(false)
                setIrregularStoreHourError(true)
            })
    }

    const createUpdateRevenueStaffing = (value, staffing) => {
        if (isNaN(value) || parseInt(value) < 0) {
            setErrorRev({ ...errorRev, [staffing.hourly_rev_high + staffing.shift]: true })
            setHlgError(true)
            return
        } else {
            const nan = isNaN(value)
            const newReqs = newStaffinReqs
            newReqs[staffing.shift + staffing.hourly_rev_high] = {
                ...staffing,
                store_id: store.id,
                workers_required: nan ? value : parseFloat(value)
            }
            setNewStaffinReqs(newReqs)
            setHlgError(false)
        }
        const oldErrors = { ...errorRev }
        delete oldErrors[staffing.hourly_rev_high + staffing.shift]
        setErrorRev(oldErrors)
    }

    const copyHour = () => {
        const mondayValues = {
            open_time: storeHours['monday']['open_time'],
            close_time: storeHours['monday']['close_time'],
            opening_staff: storeHours['monday']['opening_staff'],
            closing_staff: storeHours['monday']['closing_staff']
        }
        setStoreHours({
            monday: {
                ...storeHours.monday,
                ...mondayValues
            },
            tuesday: {
                ...storeHours.tuesday,
                ...mondayValues
            },
            wednesday: {
                ...storeHours.wednesday,
                ...mondayValues
            },
            thursday: {
                ...storeHours.thursday,
                ...mondayValues
            },
            friday: {
                ...storeHours.friday,
                ...mondayValues
            },
            saturday: {
                ...storeHours.saturday,
                ...mondayValues
            },
            sunday: {
                ...storeHours.sunday,
                ...mondayValues
            }
        })
    }

    useEffect(() => {
        if (isMobile()) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }, [])
    useEffect(() => {
        if (isAdmin) {
            AxiosWithAuth.get(`/stores/managerPresence/${segCode}`)
                .then(res => {
                    if (res.data.message === 'No manager') setManagerPresence(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [isAdmin])

    useEffect(() => {
        AxiosWithAuth.get(`/stores/${segCode}`)
            .then(res => setStore(res.data))
            .catch(err => console.log(err))
        AxiosWithAuth.get('/store_types')
            .then(res => setStoreTypes(res.data))
            .catch(err => console.log(err))
        fetchRevenueStaffings()
    }, [segCode])

    const fetchRevenueStaffings = () => {
        AxiosWithAuth.get(`/revenueStaffings/store/${segCode}`).then(res => {
            const overrides = res.data.override_staffings.filter(e => e.store_type_id)
            const storeTypeId = res.data.staffings[0]?.store_type_id
            const columns = HlgColumns.slice(1)
            const staffings = []
            columns.forEach(hourly_rev_high => {
                const workersRequiredDaytime =
                    overrides.find(e => e.hourly_rev_high === hourly_rev_high && e.shift === 'Daytime')
                        ?.workers_required ||
                    res.data.staffings.find(e => e.hourly_rev_high === hourly_rev_high && e.shift === 'Daytime')
                        ?.workers_required
                const workersRequiredNight =
                    overrides.find(e => e.hourly_rev_high === hourly_rev_high && e.shift === 'Night')
                        ?.workers_required ||
                    res.data.staffings.find(e => e.hourly_rev_high === hourly_rev_high && e.shift === 'Night')
                        ?.workers_required
                staffings.push({
                    hourly_rev_high,
                    hourly_rev_low: hourly_rev_high - 2500,
                    shift: 'Daytime',
                    store_type_id: storeTypeId,
                    workers_required: workersRequiredDaytime || '-'
                })
                staffings.push({
                    hourly_rev_high,
                    hourly_rev_low: hourly_rev_high - 2500,
                    shift: 'Night',
                    store_type_id: storeTypeId,
                    workers_required: workersRequiredNight || '-'
                })
            })
            const daytime = staffings.filter(staffing => staffing.shift === 'Daytime')
            const night = staffings.filter(staffing => staffing.shift === 'Night')
            setRevenueStaffingRows({ daytime, night })
        })
    }

    useEffect(() => {
        if (store) {
            setSelectedStoreTypeId(store.store_type_id)
            store.store_hours?.forEach(storeHour => {
                if (storeHour.day === 'irregular') {
                    const hasRow = irregularStoreHours.filter(sh => sh.id === storeHour.id).length > 0
                    if (!hasRow) {
                        setIrregularStoreHours(prev => [...prev, storeHour])
                    }
                } else {
                    setStoreHours(prev => {
                        return {
                            ...prev,
                            [storeHour.day]: {
                                id: storeHour.id,
                                open_time: storeHour.open_time,
                                close_time: storeHour.close_time,
                                opening_staff: storeHour.opening_staff,
                                closing_staff: storeHour.closing_staff
                            }
                        }
                    })
                }
            })
        }
    }, [store])

    if (!store || !storeTypes) {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }

    if (storeNameEdit) {
        return <EditStoreName current={store.name} />
    }

    if (segCodeEdit) {
        return <EditSegCode current={store.seg_code} />
    }

    return (
        <>
            <PageWithTitleLayout title="店舗情報" style={{ minHeight: 0 }}>
                <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {irregularStoreHourError && (
                        <Alert severity="error" onClose={() => setIrregularStoreHourError(false)}>
                            {store?.name} の情報を更新出来ませんでした。特別営業時間が重複している可能性があります。
                        </Alert>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', width: desktop ? '45rem' : '95%' }}>
                        <Typography
                            sx={{
                                background: '#f5f5f5',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '8px 16px',
                                height: '45px',
                                width: '45em'
                            }}
                        >
                            店舗コード：{store.seg_code}
                            {isAdmin && (
                                <IconButton
                                    variant="text"
                                    color="paloBlue"
                                    onClick={() => {
                                        setSegCodeEdit(true)
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                background: '#f5f5f5',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '8px 16px',
                                height: '45px',
                                width: '45em'
                            }}
                        >
                            店舗名：{store.name}
                            {isAdmin && (
                                <IconButton
                                    variant="text"
                                    color="paloBlue"
                                    onClick={() => {
                                        setStoreNameEdit(true)
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>
                            )}
                        </Typography>
                    </div>
                    {store.store_seg_code_versions?.length > 1 && (
                        <div style={{ display: 'flex', justifyContent: 'center', width: desktop ? '45rem' : '95%' }}>
                            <Typography
                                sx={{
                                    background: '#f5f5f5',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '8px 16px',
                                    height: '45px',
                                    width: '45em'
                                }}
                            >
                                （過去の店舗コード：
                                {[...new Set(store.store_seg_code_versions
                                    .map(version => version.seg_code)
                                    .filter(code => code !== store.seg_code))]
                                    .join(', ')}
                                ）
                            </Typography>
                        </div>
                    )}
                    {!managerPresence && (
                        <Alert severity="warning" sx={{ width: desktop ? '45rem' : '95%', marginTop: '1rem' }}>
                            この店舗にマネージャーがいません。マネージャーを登録してください。
                        </Alert>
                    )}

                    {isAdmin && (
                        <div style={{ width: desktop ? '45rem' : '95%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                sx={{ mt: '1rem' }}
                                variant="contained"
                                color="paloBlue"
                                onClick={() => {
                                    history.push(PATHS.adminStoreUserList.replace(':segCode', store.seg_code))
                                }}
                            >
                                この店舗の従業員リスト
                            </Button>
                        </div>
                    )}
                    {store && storeTypes.length > 0 && selectedStoreTypeId && (
                        <div style={{ display: 'flex', justifyContent: 'center', width: desktop ? '45rem' : '95%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '45em'
                                }}
                            >
                                <InputLabel sx={{ my: '0.5rem' }} id="storeType">
                                    店舗タイプ
                                </InputLabel>
                                <Select
                                    data-testid="storeTypeSelect"
                                    fullWidth
                                    labelId="storeType"
                                    placeholder="選択してください"
                                    value={selectedStoreTypeId}
                                    onChange={e => setSelectedStoreTypeId(e.target.value)}
                                >
                                    {storeTypes.map(storeType => (
                                        <MenuItem key={storeType.id} value={storeType.id}>
                                            {storeType.type_name} - {storeType.brand_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <InputLabel sx={{ mt: '1rem', mb: '0.5rem' }} id="storeHours">
                                    通常営業時間
                                </InputLabel>
                            </div>
                        </div>
                    )}
                    {storeHours && (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                    justifyContent: 'center'
                                }}
                            >
                                <div style={{ width: desktop ? '45rem' : '95%' }}>
                                    {Object.keys(WEEKDAY_MAPPINGS).map(day => (
                                        <div
                                            key={day}
                                            style={{
                                                margin: '1rem auto',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            data-testid="opening-hours"
                                        >
                                            <Typography sx={{ m: '0.5rem' }}>{WEEKDAY_MAPPINGS[day]}</Typography>
                                            <TextField
                                                fullWidth
                                                type="time"
                                                label="開店時間"
                                                sx={{ mx: '0.5rem' }}
                                                style={{ width: desktop ? '' : '8rem' }}
                                                value={storeHours[day].open_time}
                                                onChange={e => {
                                                    setStoreHours(prev => ({
                                                        ...prev,
                                                        [day]: {
                                                            ...prev[day],
                                                            open_time: e.target.value
                                                        }
                                                    }))
                                                }}
                                                inputProps={{ step: 900 }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="開店固定時間"
                                                sx={{ mx: '0.5rem' }}
                                                style={{ width: desktop ? '' : '8rem' }}
                                                value={storeHours[day].opening_staff}
                                                inputProps={{ min: 0 }}
                                                onChange={e => {
                                                    setStoreHours(prev => ({
                                                        ...prev,
                                                        [day]: {
                                                            ...prev[day],
                                                            opening_staff: e.target.value
                                                        }
                                                    }))
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                fullWidth
                                                type="time"
                                                label="閉店時間"
                                                sx={{ mx: '0.5rem' }}
                                                style={{ width: desktop ? '' : '8rem' }}
                                                value={storeHours[day].close_time}
                                                onChange={e => {
                                                    setStoreHours(prev => ({
                                                        ...prev,
                                                        [day]: {
                                                            ...prev[day],
                                                            close_time: e.target.value
                                                        }
                                                    }))
                                                }}
                                                inputProps={{ step: 900 }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="閉店固定時間"
                                                sx={{ mx: '0.5rem' }}
                                                style={{ width: desktop ? '' : '8rem' }}
                                                value={storeHours[day].closing_staff}
                                                inputProps={{ min: 0 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={e => {
                                                    setStoreHours(prev => ({
                                                        ...prev,
                                                        [day]: {
                                                            ...prev[day],
                                                            closing_staff: e.target.value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <IconButton onClick={copyHour}>
                                    <ContentCopyOutlined />
                                </IconButton>
                            </div>
                        </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: desktop ? '45rem' : '95%' }}>
                            <Typography variant="body1" sx={{ mt: '1rem', mb: '0.5rem' }} id="irregularStoreHours">
                                特別営業時間
                            </Typography>
                            <div style={{ maxWidth: '90vw' }}>
                                <Typography sx={{ color: 'gray', mb: '1.5rem' }} variant="body2">
                                    新たに特別営業時間を設定する場合は、下の「＋特別営業時間を追加」ボタンを押し、開始日・終了日・開店時間・閉店時間をセットして、「保存する」を押してください。削除する場合は、ゴミ箱アイコンを押してから、下の「保存する」ボタンを押してください。再度ゴミ箱をクリックすると、削除候補から外れます。
                                </Typography>
                            </div>
                            <div style={{ maxWidth: '95vw' }}>
                                <Button
                                    variant="outlined"
                                    color="paloBlue"
                                    sx={{
                                        width: desktop ? '50em' : '100%',
                                        marginBottom: '2rem'
                                    }}
                                    startIcon={<AddOutlined />}
                                    onClick={() => {
                                        setIrregularStoreHours(prev => [
                                            ...prev,
                                            {
                                                valid_from: '',
                                                valid_through: '',
                                                open_time: storeHours['monday']['open_time'],
                                                close_time: storeHours['monday']['close_time'],
                                                day: 'irregular'
                                            }
                                        ])
                                    }}
                                >
                                    特別営業時間を追加
                                </Button>
                                {irregularStoreHours.map((sh, idx) => (
                                    <div key={idx} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ my: '0.3em', width: '0.5em' }} id="irregularStoreHours">
                                            {idx + 1}
                                        </Typography>

                                        <Stack key={idx} spacing={2} sx={{ mb: '1rem', justifyContent: 'center' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    type="date"
                                                    label="開始日"
                                                    disabled={irregularStoreHours[idx]._destroy}
                                                    helperText={irregularStoreHours[idx]._destroy ? '削除候補' : ''}
                                                    sx={{ width: desktop ? '46%' : '8rem', marginBottom: '1em' }}
                                                    value={sh.valid_from}
                                                    onChange={e => {
                                                        setIrregularStoreHours(prev => {
                                                            const newIrregularStoreHours = [...prev]
                                                            newIrregularStoreHours[idx].valid_from = e.target.value
                                                            return newIrregularStoreHours
                                                        })
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    type="date"
                                                    label="終了日"
                                                    disabled={irregularStoreHours[idx]._destroy}
                                                    helperText={irregularStoreHours[idx]._destroy ? '削除候補' : ''}
                                                    sx={{ width: desktop ? '46%' : '8rem', marginBottom: '1em' }}
                                                    value={sh.valid_through}
                                                    onChange={e => {
                                                        setIrregularStoreHours(prev => {
                                                            const newIrregularStoreHours = [...prev]
                                                            newIrregularStoreHours[idx].valid_through = e.target.value
                                                            return newIrregularStoreHours
                                                        })
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    type="time"
                                                    label="開店時間"
                                                    disabled={irregularStoreHours[idx]._destroy}
                                                    helperText={irregularStoreHours[idx]._destroy ? '削除候補' : ''}
                                                    sx={{ width: desktop ? '46%' : '8rem', marginBottom: '1em' }}
                                                    value={sh.open_time}
                                                    inputProps={{ step: 900 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={e => {
                                                        setIrregularStoreHours(prev => {
                                                            const newIrregularStoreHours = [...prev]
                                                            newIrregularStoreHours[idx] = {
                                                                ...newIrregularStoreHours[idx],
                                                                open_time: e.target.value
                                                            }
                                                            return newIrregularStoreHours
                                                        })
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    type="time"
                                                    label="閉店時間"
                                                    disabled={irregularStoreHours[idx]._destroy}
                                                    helperText={irregularStoreHours[idx]._destroy ? '削除候補' : ''}
                                                    sx={{ width: desktop ? '46%' : '8rem', marginBottom: '1em' }}
                                                    value={sh.close_time}
                                                    inputProps={{ step: 900 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={e => {
                                                        setIrregularStoreHours(prev => {
                                                            const newIrregularStoreHours = [...prev]
                                                            newIrregularStoreHours[idx] = {
                                                                ...newIrregularStoreHours[idx],
                                                                close_time: e.target.value
                                                            }
                                                            return newIrregularStoreHours
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </Stack>
                                        <IconButton
                                            sx={{ width: '0.5em', marginBottom: '1.5em' }}
                                            onClick={() => {
                                                setIrregularStoreHours(prev => {
                                                    const newIrregularStoreHours = [...prev]
                                                    if (newIrregularStoreHours[idx]._destroy) {
                                                        newIrregularStoreHours[idx] = {
                                                            ...newIrregularStoreHours[idx],
                                                            _destroy: false
                                                        }
                                                    } else {
                                                        newIrregularStoreHours[idx] = {
                                                            ...newIrregularStoreHours[idx],
                                                            _destroy: true
                                                        }
                                                    }
                                                    return newIrregularStoreHours
                                                })
                                            }}
                                        >
                                            {irregularStoreHours[idx]._destroy ? <RestoreFromTrash /> : <Delete />}
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </PageWithTitleLayout>
            <PageWithTitleLayout title="HLG表">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {hlgError && (
                        <Alert severity="error" onClose={() => setHlgError(false)}>
                            {store?.name}{' '}
                            の情報を更新出来ません。「モデル（人数）」の数値を除く1以上の半角数字を入力してください。
                        </Alert>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '1rem',
                            justifyContent: 'space-between',
                            width: desktop ? '45em' : '95%'
                        }}
                    >
                        <TextField
                            type="time"
                            label="夜間時間開始"
                            error={nightStartError}
                            style={{ width: '50%' }}
                            value={store.night_start && store.night_start + ':00:00'}
                            onChange={e => {
                                setNightStartSaving(true)
                                const time = e.target.value.split(':')[0]
                                const hour = parseInt(time, 10);
                                if (!isNaN(hour) && hour >= 0 && hour <= 23) {
                                    AxiosWithAuth.put(`/stores/${store.seg_code}`, { night_start: time })
                                        .then(() => {
                                            setNightStartError(false);
                                            setNightStartSaving(false);
                                            setStore(prev => {
                                                return {...prev, night_start: time }
                                            });
                                        })
                                        .catch(err => {
                                            setNightStartError(err.response.data.night_start[0]);
                                            setNightStartSaving(false);
                                        });
                                } else {
                                    setNightStartError('Invalid time. Please enter a number between 0 and 23.');
                                    setNightStartSaving(false);
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    nightStartSaving ? <CircularProgress size={24} /> : <CheckCircleOutline style={{ color: 'green' }} />
                                )
                            }}
                        />
                        <ToggleButtonGroup
                            color="primary"
                            value={viewNightStaffing}
                            exclusive
                            onChange={() => setViewNightStaffing(prev => !prev)}
                        >
                            <ToggleButton
                                value={false}
                                style={{
                                    color: 'rgba(63, 81, 181, 1)',
                                    borderColor: 'rgba(63, 81, 181, 0.5)',
                                    width: '5rem'
                                }}
                            >
                                日中
                            </ToggleButton>
                            <ToggleButton
                                value={true}
                                style={{
                                    color: 'rgba(63, 81, 181, 1)',
                                    borderColor: 'rgba(63, 81, 181, 0.5)',
                                    width: '5rem'
                                }}
                            >
                                夜間
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Table sx={{ width: desktop ? '45rem' : '95%', marginTop: '1rem' }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '5rem' }} align="center">
                                    売上（円）
                                </TableCell>
                                <TableCell sx={{ width: '5rem' }} align="center">
                                    必要人数
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {!viewNightStaffing && (
                            <TableBody>
                                {revenueStaffingRows.daytime
                                    ?.sort((a, b) => a.hourly_rev_low - b.hourly_rev_low)
                                    .map(e => {
                                        return (
                                            <TableRow key={e.hourly_rev_high}>
                                                <TableCell align="center">
                                                    {e.hourly_rev_high?.toLocaleString('ja-JP')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Input
                                                        defaultValue={e.workers_required}
                                                        sx={{
                                                            width: '2rem',
                                                            backgroundColor:
                                                                errorRev[e.hourly_rev_high + e.shift] &&
                                                                'rgba(240, 58, 58, .4)'
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'center' }
                                                        }}
                                                        onBlur={event =>
                                                            createUpdateRevenueStaffing(event.target.value, e)
                                                        }
                                                        onKeyPress={event =>
                                                            event.key === 'Enter' && event.target.blur()
                                                        }
                                                        error={errorRev[e.hourly_rev_high + e.shift]}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        )}
                        {viewNightStaffing && (
                            <TableBody>
                                {revenueStaffingRows.night
                                    ?.sort((a, b) => a.hourly_rev_low - b.hourly_rev_low)
                                    .map(e => {
                                        return (
                                            <TableRow key={e.hourly_rev_high}>
                                                <TableCell align="center">
                                                    {e.hourly_rev_high?.toLocaleString('ja-JP')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Input
                                                        defaultValue={e.workers_required}
                                                        sx={{ width: '2rem' }}
                                                        inputProps={{
                                                            style: { textAlign: 'center' }
                                                        }}
                                                        onBlur={event =>
                                                            createUpdateRevenueStaffing(event.target.value, e)
                                                        } // when input changes to unfocused
                                                        onKeyPress={event =>
                                                            event.key === 'Enter' && event.target.blur()
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        )}
                    </Table>
                    <Stack direction="column" spacing={2} sx={{ my: '2rem' }} style={{ width: desktop ? '' : '95%' }}>
                        <Button
                            variant="contained"
                            color="paloBlue"
                            className="main-button"
                            onClick={() => {
                                setConfirmationOpen(true)
                            }}
                            disabled={saveButtonDisabled || Object.keys(errorRev).length > 0}
                        >
                            保存する
                        </Button>
                        <Dialog
                            open={confirmationOpen}
                            onClose={() => {
                                setConfirmationOpen(false)
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            disableEscapeKeyDown={true}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                        >
                            {saveButtonDisabled ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: '1rem'
                                    }}
                                >
                                    <CircularProgress />
                                    <div>変更を保存中…</div>
                                </div>
                            ) : (
                                <>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            変更内容を保存しますか？
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setConfirmationOpen(false)}>キャンセル</Button>
                                        <Button onClick={saveStore} autoFocus>
                                            OK
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        </Dialog>
                    </Stack>
                </div>
            </PageWithTitleLayout>
        </>
    )
}

export default EditStore
