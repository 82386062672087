import React, { useState, useContext, useEffect } from 'react'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { Tab,
        Box
} from '@mui/material'
import { TabPanel,
    TabContext,
    TabList,
} from '@mui/lab'
import { isMobile } from '../../Utilities/isMobile'
import { useStickyState } from '../../Utilities/useStickyState'
import SubmittedEmployees from '../CreateShift/SubmittedEmployees'
import MonthlyShiftHeader from '../MonthlyShift/MonthlyShiftHeader'
import CheckShiftDay from '../CheckShift/CheckShiftDay'
import Calendar from '../SubmitShift/Calendar'
import CheckFinalizedCalendar from '../CheckShift/CheckFinalizedCalendar'
import HLGAdjustments from '../HLGAdjustments/HLGAdjustments'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { useParams } from 'react-router-dom'
import { PATHS } from '../../constants'

const ShiftManagements = ({ history }) => {
    const { calendarYearMonth, tabValue: tabValueFromParam } = useParams()
    const [tabValue, setTabValue] = useStickyState(tabValueFromParam, 'frederickTabValue')
    const selectedYearMonth = JSON.parse(localStorage.getItem('frederickSelectedYearMonth'))
    const [showMonthlyView, setShowMonthlyView] = useState(calendarYearMonth === selectedYearMonth)
    const [shiftDate, setShiftDate] = useState(!showMonthlyView ? calendarYearMonth : '')
    const { isManager, canEditShift, isAdmin } = useContext(EmploymentsContext)
    const hasCreatePermission = isManager || isAdmin || canEditShift

    useEffect(() => {
        if (shiftDate === '') {
            history.push(PATHS.shiftManagement.replace(':employmentId', localStorage.selected_emp).replace(':calendarYearMonth', selectedYearMonth).replace(':tabValue', tabValue))
            setShowMonthlyView(true)
        } else {
            history.push(history.location.pathname.replace(calendarYearMonth, shiftDate))
        }
    }, [shiftDate])

    useEffect(() => {
        if (tabValueFromParam && tabValueFromParam !== tabValue) {
            setTabValue(tabValueFromParam)
        }
    }, [tabValueFromParam])

    return (
        <PageWithTitleLayout title="シフト管理">
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => { history.push(history.location.pathname.replace(tabValue, newValue)); setTabValue(newValue)}}>
                        {hasCreatePermission && <Tab label="シフト提出状況" value={'status'} />}
                        {hasCreatePermission && <Tab label="シフト作成"  value={'create'} />}
                        <Tab label="シフト確認" value={'check'} />
                        <Tab label="シフト提出" value={'submit'} />
                        <Tab label="シフト調整" value={'adjust'} />
                    </TabList>
                </Box>
                <TabPanel value={'status'}>
                    <SubmittedEmployees history={history} setTabValue={setTabValue}/>
                </TabPanel>
                <TabPanel value={'create'} sx={{p: isMobile() && 0}}>
                    {isMobile() ? <>
                        {showMonthlyView && <CheckFinalizedCalendar history={history} setShowMonthlyView={setShowMonthlyView} setShiftDate={setShiftDate}/>}
                        {!showMonthlyView && <CheckShiftDay history={history} setShowMonthlyView={setShowMonthlyView} shiftDate={shiftDate} setShiftDate={setShiftDate}/>}
                    </> : <>
                        {showMonthlyView && <MonthlyShiftHeader history={history} setShowMonthlyView={setShowMonthlyView} setShiftDate={setShiftDate}/>}
                        {!showMonthlyView && <CheckShiftDay history={history} setShowMonthlyView={setShowMonthlyView} shiftDate={shiftDate} setShiftDate={setShiftDate}/>}
                    </>}
                </TabPanel>
                <TabPanel value={'check'} sx={{p: isMobile() && 0}}>
                    {isMobile() ? <>
                        {showMonthlyView && <CheckFinalizedCalendar history={history} setShowMonthlyView={setShowMonthlyView} setShiftDate={setShiftDate}/>}
                        {!showMonthlyView && <CheckShiftDay history={history} setShowMonthlyView={setShowMonthlyView} shiftDate={shiftDate} setShiftDate={setShiftDate}/>}    
                    </> : <>
                        {showMonthlyView && <MonthlyShiftHeader history={history} setShowMonthlyView={setShowMonthlyView} setShiftDate={setShiftDate}/>}
                        {!showMonthlyView && <CheckShiftDay history={history} setShowMonthlyView={setShowMonthlyView} shiftDate={shiftDate} setShiftDate={setShiftDate}/>}
                    </>}
                </TabPanel>
                <TabPanel value={'submit'} sx={{p: isMobile() && 0}}>
                    <Calendar history={history}/>
                </TabPanel>
                <TabPanel value={'adjust'}>
                    <HLGAdjustments />
                </TabPanel>
            </TabContext>
        </PageWithTitleLayout>

    )
}

export default ShiftManagements