import React, { useEffect, useState } from 'react'
import { MenuItem, ListItemIcon, Select } from '@mui/material'
import { PeopleAlt } from '@mui/icons-material'
import { changeSelectedEmployee } from '../Utilities/authenticationService'
import { displayNameWithSpace } from '../Utilities/japaneseStringUtils'
import { isMobile } from '../Utilities/isMobile'

const SelectedEmployee = ({ employees, setSubmitFor, submitFor }) => {
    const [selectedEmployee, setSelectedEmployee] = useState()

    useEffect(() => {
        if (employees.length === 0) return
        setSelectedEmployee(employees.find(e => e.id === parseInt(submitFor)))
    }, [employees])

    const handleChange = e => {
        setSelectedEmployee(employees.find(emp => emp.id === e.target.value))
        changeSelectedEmployee(e.target.value)
        setSubmitFor(e.target.value)
    }

    if (selectedEmployee)
        return (
            <div
                style={{
                    margin: '.5rem auto',
                    background: 'rgba(0, 0, 0, .04)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: isMobile() ? '95%' : '620px'
                }}
            >
                <Select
                    size="small"
                    sx={{ border: 'none', mx: 'auto' }}
                    fullWidth
                    defaultValue=""
                    value={selectedEmployee.id} // really doesn't like objects as values
                    onChange={handleChange}
                >
                    {employees.map(e => (
                        <MenuItem
                            value={e.id}
                            key={e.id}
                            sx={{
                                background: 'rgba(0, 0, 0, .04)'
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                                    <PeopleAlt />
                                </ListItemIcon>
                                {displayNameWithSpace(e.user)}
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    return <></>
}

export default SelectedEmployee
