import React, { useEffect, useState } from 'react';
import { Typography,
    Card,
    CardContent,
    Box
} from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ShowChartOutlined,
    SentimentNeutral,
    SentimentSatisfiedAltOutlined,
    SentimentVeryDissatisfied,
    CommentOutlined
} from '@mui/icons-material';
import { useParams } from 'react-router-dom'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout';
import { AxiosWithAuth } from '../../Utilities/authenticationService';

const FeedbackManagements = () => {
    const { calendarYearMonth, employmentId } = useParams()
    const [ratingRatios, setRatingRatios] = useState()
    const [ratingCounts, setRatingCounts] = useState()
    const [feedbacks, setFeedbacks] = useState([])
    const year = calendarYearMonth.slice(0, 4)
    const month = calendarYearMonth.slice(5, 7)
    const formattedCalendarYearMonth = `${year}年${month}月`

    const iconMapping = {
        good: <SentimentSatisfiedAltOutlined />,
        neutral: <SentimentNeutral />,
        bad: <SentimentVeryDissatisfied />
    }
    useEffect(() => {
        AxiosWithAuth.get(`/feedbacks`, {
            params: {
                year_month: calendarYearMonth,
                employment_id: employmentId
            }
        }).then(res => {
                let good = 0
                let neutral = 0
                let bad = 0
                let comments = []
                res.data.forEach(feedback => {
                    if (feedback.rating === 'good') {
                        good++
                    } else if (feedback.rating === 'neutral') {
                        neutral++
                    } else {
                        bad++
                    }
                    comments.push(feedback.comment)
                })
                setRatingCounts({ good, neutral, bad })
                setRatingRatios({
                    good: Math.round(good / res.data.length * 100),
                    neutral: Math.round(neutral / res.data.length * 100),
                    bad: Math.round(bad / res.data.length * 100)
                })
                setFeedbacks(res.data)

            })
            .catch(err => {
                console.log(err)
            })
    }, [calendarYearMonth, employmentId])

    return (
        <PageWithTitleLayout title="総合評価グラフ" disableStoreSelect>
            <Typography sx={{ ml: 5, mt: 5 }}>
                {formattedCalendarYearMonth}
            </Typography>

            <Card sx={{ m: 5, width: '50vw' }}>
                <CardContent>
                    <Typography variant="h7" component="div" sx={{ display: 'flex', alignContent: 'center', fontWeight: 'bold' }}>
                        <ShowChartOutlined sx={{ mr: 1 }} />
                        評価グラフ
                    </Typography>
                    {feedbacks.length ? 
                        <PieChart
                            width={500}
                            height={300}
                            series={[
                                {
                                    data: [
                                        { id: 'good', label: `希望通り ${ratingCounts.good}人`, value: ratingRatios.good, color: '#3FC8E4' },
                                        { id: 'neutral', label: `まあまあ ${ratingCounts.neutral}人`, value: ratingRatios.neutral, color: '#5654D4' },
                                        { id: 'bad', label: `不満 ${ratingCounts.bad}人`, value: ratingRatios.bad, color: '#FB3B52' }
                                    ],
                                    type: 'pie',
                                    innerRadius: 65,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: 0,
                                    endAngle: 360,
                                    cx: 150,
                                    cy: 150,
                                    arcLabel: (d) => `${d.value}%`,
                                    arcLabelsSkipAngle: 35,
                                    arcLabelsRadius: '60%'
                                }
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold'
                                },                                
                              }}
                        />
                        :
                        <Typography variant="h7" component="div" sx={{ mt: 3, display: 'flex', alignContent: 'center' }}>
                            今月はまだ評価がありません
                        </Typography>
                    }
                </CardContent>
            </Card>
            <Card sx={{ m: 5 }}>
                <CardContent>
                    <Typography variant="h7" component="div" sx={{ display: 'flex', alignContent: 'center', fontWeight: 'bold' }}>
                        <CommentOutlined sx={{ mr: 1 }} />
                        評価コメント
                    </Typography>
                    {feedbacks.length ? 
                        feedbacks.map((f, index) => (<Box key={index} display='flex' alignItems='center' sx={{ m:2 }}>
                            {iconMapping[f.rating]}
                            <Typography key={index} variant="body1" sx={{ ml: 1 }}>
                                {f.comment}
                            </Typography>
                        </Box>))
                        :
                        <Typography variant="h7" component="div" sx={{ mt: 3, display: 'flex', alignContent: 'center' }}>
                            今月はまだコメントがありません
                        </Typography>
                    }
                </CardContent>
            </Card>
        </PageWithTitleLayout>

    )
}

export default FeedbackManagements;