import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
    Typography,
    Button,
    Snackbar,
    Alert,
    Divider,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Stack,
    TextareaAutosize,
    TextField,
    Popover
} from '@mui/material'
import {
    EditOutlined,
    PeopleAlt,
    Circle,
    CalendarToday,
    DeleteOutline,
    AutorenewOutlined,
    VpnKeyRounded,
    ChatBubbleOutline,
    CompressOutlined,
    ExpandOutlined,
    AddOutlined,
    AddCommentOutlined,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    PersonAddAlt1,
} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { ERROR_COLOR, ROLE_MAPPINGS, CONFIRMED, BLOCKED, FUSOKU, PALO_BLUE } from '../../constants'
import './checkShift.css'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import { useParams } from 'react-router-dom'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import {
    InputTime,
    parseTimeBlock,
    splitShiftsIntoBlocks,
    stitchShifts,
    timezoneOffsets
} from '../../Utilities/shiftUtils'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import moment from 'moment'
import PdfBulkExportButton from '../ShiftPdfExport/PdfBulkExportButton'
import DesktopCalendar from './Calendars/DesktopCalendar'
import MobileCalendar from './Calendars/MobileCalendar'
import SubmitShift from '../SubmitShift/SubmitShift'
import { Box } from '@mui/system'
import { isMobile } from '../../Utilities/isMobile'
import { PATHS, MEMO_MAX_ROWS } from '../../constants'
import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'
import { Prompt } from 'react-router-dom'
import EditDailyNoteTemplateDrawer from './EditDailyNoteTemplateDrawer'
import useOrientation from '../../Utilities/useOrientation'

export const getStaffReq = (staffRequirements = [], operationHours, shiftDate) => {
    const filteredStaffReqs = staffRequirements.filter(req => {
        const open_num = parseInt(operationHours?.open_time.split(':')[0])
        const close_num = parseInt(operationHours?.close_time.split(':')[0])
        if (req.day === shiftDate) {
            if (operationHours?.next_day_end) {
                return req.hour >= open_num - 1
            }
            return req.hour >= open_num - 1 && req.hour <= close_num + 1
        } else if (req.day === moment(shiftDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')) {
            return req.hour <= close_num + 1
        }
        return false
    })
    const full = filteredStaffReqs.reduce(
        (acc, { staff_required, customer_staff_required }) => acc + (customer_staff_required || staff_required),
        0
    )

    const newHoursWithStaffReq = {}
    filteredStaffReqs.forEach(staffReq => {
        if (staffReq.staff_required > 0 || staffReq.customer_staff_required > 0) {
            newHoursWithStaffReq[moment(staffReq.day, 'YYYY-MM-DD').hour(staffReq.hour).format('D-H')] =
                staffReq.customer_staff_required || staffReq.staff_required
        }
    })
    return [filteredStaffReqs, { ...newHoursWithStaffReq, fullCount: full }]
}
export const getShifts = (shifts = [], store, shiftRequests = []) => {
    const seenTimeBlocks = {}
    let isReleased = false
    let rowNumber = 0
    const mappedShifts = shifts
        .filter(shift =>
            seenTimeBlocks[shift.time_block + shift.employment_id + shift.status]
                ? false
                : (seenTimeBlocks[shift.time_block + shift.employment_id + shift.status] = true)
        )
        .map(shift => {
            if (shift.is_released) isReleased = true
            return {
                ...shift,
                startDate: parseTimeBlock(shift.time_block, timezoneOffsets[store.time_zone])[0],
                endDate: parseTimeBlock(shift.time_block, timezoneOffsets[store.time_zone])[1],
                title: '',
                employment_id: shift.employment_id
            }
        })
    shiftRequests?.forEach(shift => {
        rowNumber = Math.max(rowNumber, parseInt(shift.row_number))
        shift.employment_id = 'fusoku' + shift.row_number
        shift.startDate = parseTimeBlock(shift.time_block, timezoneOffsets[store.time_zone])[0]
        shift.endDate = parseTimeBlock(shift.time_block, timezoneOffsets[store.time_zone])[1]
        shift.title = ''
        shift.status = 'confirmed'
        shift.is_released = isReleased
    })
    return [mappedShifts, shiftRequests, rowNumber]
}
const onBeforeUnload = e => {
    e.returnValue = true
}

const CheckShiftDay = ({ 
    history,
    setSnackbar,
    setShiftDate,
    shiftDate,
    setShowMonthlyView }) => {
    const { employmentId } = useParams()
    const [originalShifts, setOriginalShifts] = useState([])
    const [employments, setEmployments] = useState([])
    const [staffRequirements, setStaffRequirements] = useState([])
    const [hoursWithStaffReq, setHoursWithStaffReq] = useState({})
    const { isManager, isManagerFor, canEditShiftFor, userInfo } = useContext(EmploymentsContext)
    const [shifts, setShifts] = useState([])
    const [nonConfirmedShifts, setNonConfirmedShifts] = useState([])
    const [confirmedShifts, setConfirmedShifts] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [editing, setEditing] = useState(document.location.pathname.includes('create'))
    const [predictedSales, setPredictedSales] = useState(0)
    const [store, setStore] = useState()
    const [isOverwriting, setIsOverwriting] = useState(false)
    const [dialogue, setDialogue] = useState(false)
    const [startError, setStartError] = useState(false)
    const [endError, setEndError] = useState(false)
    const [modalEmployment, setModalEmployment] = useState()
    const [modalStartTime, setModalStartTime] = useState('12:00')
    const [modalEndTime, setModalEndTime] = useState('12:00')
    const [modalBreakStartTime, setModalBreakStartTime] = useState('')
    const [modalBreakEndTime, setModalBreakEndTime] = useState('')
    const [breakStartError, setBreakStartError] = useState(false)
    const [breakEndError, setBreakEndError] = useState(false)
    const [modalDate, setModalDate] = useState()
    const [desktop, setDesktop] = useState(false)
    const [originalData, setOriginalData] = useState()
    const [storeHour, setStoreHour] = useState()
    const [dailyNotes, setDailyNotes] = useState({})
    const [memoLoading, setMemoLoading] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(null)
    const [collapsedRows, setCollapsedRows] = useState(false)
    const [changed, setChanged] = useState(false)
    const [fusokuNumber, setFusokuNumber] = useState(0)
    const [blockers, setBlockers] = useState([])
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [editTemplate, setEditTemplate] = useState(false)
    const [dailyNotePopoverAnchorEl, setDailyNotePopoverAnchorEl] = useState(null)
    const [templates, setTemplates] = useState([])
    const [submitShiftDialog, setSubmitShiftDialog] = useState(false)
    const [suggestedStartEnd, setSuggestedStartEnd] = useState()
    const orientation = useOrientation()
    
    const dailyNotesPopoverOpen = Boolean(dailyNotePopoverAnchorEl)
    const popoverId = dailyNotesPopoverOpen ? 'simple-popover' : undefined
    useEffect(() => {
        if (store?.id) {
            setIsDataLoaded(true)
        }
    }, [store])
    const handleTextareaChange = e => {
        const lines = e.target.value.split('\n')
        if (lines.length <= MEMO_MAX_ROWS) {
            // Limit rows
            setDailyNotes(prevNotes => ({
                ...prevNotes,
                note: e.target.value
            }))
        } else {
            setSnackbar({ message: 'メモは５行まで編集可能です', severity: 'error' })
            return
        }
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const moveToNextDay = () => {
        setEditing(document.location.pathname.includes('create'))
        const nextDay = moment(shiftDate, 'YYYY-MM-DD').add(1, 'days')
        setShiftDate(nextDay.format('YYYY-MM-DD'))
        scrollToTop()
    }

    const moveToPreviousDay = () => {
        setEditing(document.location.pathname.includes('create'))
        const previousDay = moment(shiftDate, 'YYYY-MM-DD').subtract(1, 'days')
        setShiftDate(previousDay.format('YYYY-MM-DD'))
        scrollToTop()
    }

    const returnToMonthlyShift = (resubmit = false) => {
        const month = moment(shiftDate, 'YYYY-MM-DD').format('YYYY-MM')
        if (isMobile()) {
            if (isChecking) {
                history.push({
                    pathname: PATHS.checkCalendar
                        .replace(':employmentId', employmentId)
                        .replace(':calendarYearMonth', month),
                    state: { resubmit: resubmit }
                })
            } else {
                history.push({
                    pathname: PATHS.createCalendar
                        .replace(':employmentId', employmentId)
                        .replace(':calendarYearMonth', month),
                    state: { resubmit: resubmit }
                })
            }
        } else {
            if (isChecking) {
                history.push({
                    pathname: PATHS.checkMonthlyShift
                        .replace(':employmentId', employmentId)
                        .replace(':calendarYearMonth', month),
                    state: { resubmit: resubmit }
                })
            } else {
                history.push({
                    pathname: PATHS.createMonthlyShift
                        .replace(':employmentId', employmentId)
                        .replace(':calendarYearMonth', month),
                    state: { resubmit: resubmit }
                })
            }
        }
        scrollToTop()
    }

    const getStaffRequired = hour => {
        const requirements = staffRequirements?.find(staffReq => staffReq.hour === hour)
        return [
            requirements?.customer_staff_required || requirements?.staff_required,
            requirements?.customer_preds || requirements?.pred_sales
        ]
    }

    const checkIfFullyStaffed = (day, hour, staffRequired) => {
        let shiftCount = 0
        shifts.forEach(shift => {
            if (
                shift.status === 'confirmed' &&
                shift.startDate.getHours() === hour &&
                shift.startDate.getDate() === day
            ) {
                shiftCount++
            }
        })
        return [shiftCount / 4 >= staffRequired, shiftCount / 4]
    }

    const staffCountForPredictedTimeSlots = () => {
        let shiftCount = 0
        const hourCounts = {}
        Object.keys(hoursWithStaffReq).forEach(hour => {
            hourCounts[hour] = 0
        })
        shifts.forEach(shift => {
            if (
                shift.status === 'confirmed' &&
                hoursWithStaffReq[shift.startDate.getDate() + '-' + shift.startDate.getHours()] &&
                hourCounts[shift.startDate.getDate() + '-' + shift.startDate.getHours()] <
                    hoursWithStaffReq[shift.startDate.getDate() + '-' + shift.startDate.getHours()]
            ) {
                hourCounts[shift.startDate.getDate() + '-' + shift.startDate.getHours()] += 0.25
                shiftCount += 0.25
            }
        })
        return shiftCount
    }
    const staffCountForAllTimeSlots = () => {
        let shiftCount = 0
        const hourCounts = {}
        if (!storeHour) return 0
        const startHour = parseInt(storeHour.open_time.split(':')[0])
        const endHour = parseInt(storeHour.close_time.split(':')[0]) + (storeHour.next_day_end ? 24 : 0)
        const date = moment(shiftDate, 'D')
        for (let i = startHour; i <= endHour; i++) {
            const key = date + '-' + i
            hourCounts[key] = 0
        }

        shifts.forEach(shift => {
            if (shift.status === 'confirmed') {
                const key = date + '-' + shift.startDate.getHours()
                hourCounts[key] += 0.25
                shiftCount += 0.25
            }
        })
        return shiftCount
    }

    const fullDayStaffed = () => {
        const staffCountForPrediction = staffCountForPredictedTimeSlots()
        const staffCountForAllTime = staffCountForAllTimeSlots()
        const insufficient =
            (isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo?.view_only) &&
            staffCountForPrediction < hoursWithStaffReq?.fullCount
        const sufficient =
            (isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo?.view_only) &&
            staffCountForPrediction === staffCountForAllTime

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PeopleAlt
                    fontSize="string"
                    sx={{
                        color: insufficient ? ERROR_COLOR : sufficient ? 'inherit' : PALO_BLUE
                    }}
                />
                <Typography
                    style={{
                        marginLeft: 5,
                        fontSize: 12,
                        color: insufficient ? ERROR_COLOR : sufficient ? 'inherit' : PALO_BLUE
                    }}
                >
                    {staffCountForAllTime}/{hoursWithStaffReq.fullCount}
                </Typography>
            </div>
        )
    }

    const handleCalendarClick = shift => {
        if (!editing && shift.row_number) {
            const [startTime, endTime] = shift.time_block.split('..').map(time => {
                const isoTime = time.replace(' ', 'T').replace(' UTC', '.000Z')
                return moment(isoTime).format('HH:mm');
            })
            setSuggestedStartEnd([startTime, endTime])
            setSubmitShiftDialog(true)
        }
        if (userInfo?.view_only) return
        if (!editing) return
        if (shift.status === 'blocked') return
        setChanged(true)
        if (shift.status === 'confirmed') {
            if (shift.originalStatus === 'submitted') {
                shift.status = shift.originalStatus
            } else {
                shift.status = ''
            }
            setConfirmedShifts(
                confirmedShifts.filter(s => {
                    return s.id !== shift.id
                })
            )
        } else {
            shift.status = 'confirmed'
            setConfirmedShifts(prev => {
                return [...prev.filter(s => s.id !== shift.id), shift]
            })
        }
    }

    const handleLongPress = shift => {
        if (userInfo?.view_only) return
        if (!editing) return
        if (shift?.status === 'blocked') return
        setModalEmployment(shift.employment_id)
        setModalDate(shift.startDate)
        setDialogue(true)
        if (shift.status) {
            stitchShifts(
                shifts.filter(
                    e => e.employment_id == shift.employment_id && ['confirmed', 'submitted'].includes(e.status)
                ),
                timezoneOffsets[store.time_zone],
                true
            ).forEach(s => {
                if (
                    s.start_time.getTime() <= shift.startDate.getTime() &&
                    s.end_time.getTime() >= shift.endDate.getTime()
                ) {
                    setModalStartTime(moment(s.start_time).format('HH:mm'))
                    setModalEndTime(moment(s.end_time).format('HH:mm'))
                }
            })
            return
        }
        setModalStartTime(moment(shift.startDate).format('HH:mm'))
        setModalEndTime(moment(shift.endDate).format('HH:mm'))
    }

    const handleModalConfirm = () => {
        const [shour, sminute] = modalStartTime.split(':')
        const [ehour, eminute] = modalEndTime.split(':')
        const [bshour, bsminute] = modalBreakStartTime.split(':') // break start hour
        const [behour, beminute] = modalBreakEndTime.split(':') // break end hour
        
        const shift = {
            id: modalStartTime + modalEndTime + modalEmployment,
            status: 'confirmed',
            employment_id: modalEmployment,
            startDate: moment(modalDate).set({ hour: shour, minute: sminute }).toDate(),
            endDate:
                ehour < 5
                    ? moment(modalDate).set({ hour: ehour, minute: eminute }).add(1, 'day').toDate()
                    : moment(modalDate).set({ hour: ehour, minute: eminute }).toDate(),
            breakStartDate: behour < 5 ? moment(modalDate).set({ hour: bshour, minute: bsminute }).add(1, 'day').toDate() : moment(modalDate).set({ hour: bshour, minute: bsminute }).toDate(),
            breakEndDate: behour < 5 ? moment(modalDate).set({ hour: behour, minute: beminute }).add(1, 'day').toDate() : moment(modalDate).set({ hour: behour, minute: beminute }).toDate(),
            title: ''
        }
        
        const notOverlapped = confirmedShifts.filter(s => {
            return !(
                s.employment_id === modalEmployment &&
                s.startDate.getTime() >= shift.startDate.getTime() &&
                s.startDate.getTime() < shift.endDate.getTime()
            )
        })
        const split = splitShiftsIntoBlocks([shift])
        setChanged(true)
        setConfirmedShifts(() => [...notOverlapped, ...split])
    }

    const handleModalDelete = () => {
        const [shour, sminute] = modalStartTime.split(':')
        const [ehour, eminute] = modalEndTime.split(':')
        const modalStartDate = moment(modalDate).set({ hour: shour, minute: sminute }).toDate()
        const modalEndDate = moment(modalDate).set({ hour: ehour, minute: eminute }).toDate()

        const remainingShifts = confirmedShifts.filter(shift => {
            if (
                shift.employment_id != modalEmployment ||
                shift.startDate.getTime() < modalStartDate.getTime() ||
                shift.startDate.getTime() >= modalEndDate.getTime()
            ) {
                return true
            } else {
                if (shift.originalStatus === 'submitted') {
                    shift.status = shift.originalStatus
                } else {
                    shift.status = ''
                }
                return false
            }
        })
        setChanged(true)
        setConfirmedShifts(remainingShifts)
        setDialogue(false)
    }

    const handleShiftSubmit = async () => {
        setEditing(false)
        setChanged(false)
        let finishedSubmitting = false
        const stiched = stitchShifts(confirmedShifts, timezoneOffsets[store.time_zone])

        const allShifts = stiched.filter(shift => !String(shift.employment_id).includes('fusoku'))
        const fusokuShifts = stiched.filter(shift => String(shift.employment_id).includes('fusoku'))

        await AxiosWithAuth.post('shift_requests/multiple', {
            employment_id: employmentId,
            date: shiftDate,
            shifts: fusokuShifts.map(shift => ({
                store_id: store.id,
                start_time: shift.start_time,
                end_time: shift.end_time,
                row_number: shift.employment_id.split('fusoku')[1],
                ops_date: shiftDate
            }))
        })
            .then(() => {
                finishedSubmitting = true
            })
            .catch(err => {
                finishedSubmitting = false
                setSnackbar({ message: err.response?.data, severity: 'error' })
            })
        await AxiosWithAuth.post('/confirmShifts', {
            employment_id: employmentId,
            shifts: allShifts,
            ops_date: shiftDate
        })
            .then(() => {
                finishedSubmitting = true
            })
            .catch(err => {
                finishedSubmitting = false
                setSnackbar({ message: err.response?.data, severity: 'error' })
            })

        if (finishedSubmitting) {
            removeUnloadHandler()
            setSnackbarOpen(true)
            if (isChecking) {
                returnToMonthlyShift(true)
            } else {
                setShowMonthlyView(true)
                setShiftDate('')
            }
        }
    }

    moment.updateLocale('en', {
        weekdaysShort: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
    })

    const formatCurrentDate = () => {
        return moment(shiftDate, 'YYYY-MM-DD').format('YYYY年M月D日 ddd')
    }

    const generateEmploymentText = employment => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    minHeight: '5.5rem',
                    maxHeight: '5.5rem'
                }}
                key={employment.id}
            >
                <Typography sx={{ textAlign: 'center' }} style={{ fontSize: 12 }}>
                    {displayNameWithSpace(employment.user)}
                </Typography>
                <Typography style={{ fontSize: 12 }}>{employment.user.emp_cd}</Typography>
                <Typography
                    style={{
                        fontSize: employment.open_close_duty ? 12 : 14
                    }}
                >
                    {`${employment.rank || ''}${employment.open_close_duty && employment.rank ? '/' : ''}`}
                    {employment.open_close_duty ? <VpnKeyRounded style={{ fontSize: 12 }} /> : ''}
                </Typography>
                <Typography style={{ fontSize: 12 }}>{ROLE_MAPPINGS[employment.role]}</Typography>
            </div>
        )
    }

    const handleData = data => {
        setChanged(false)
        setOriginalData(JSON.parse(JSON.stringify(data))) // deep copy
        setDailyNotes({ ...data.note, original: data.note?.note })
        const [shifts, shiftRequests, rowNumber] = getShifts(data.shifts, data.store, data.shift_requests)

        const blockers = data.shift_blockers.map(blocker => ({
            ...blocker,
            status: 'blocked',
            title: '',
            startDate: parseTimeBlock(blocker.time_block, timezoneOffsets[data.store.time_zone])[0],
            endDate: parseTimeBlock(blocker.time_block, timezoneOffsets[data.store.time_zone])[1]
        }))
        setBlockers(blockers)
        let employments = data.employments
        setFusokuNumber(rowNumber)
        for (let i = 1; i <= rowNumber; i++) {
            employments.push({
                id: 'fusoku' + i,
                emp_cd: i,
                user: {
                    name_first: `${i}`,
                    name_last: '不足シフト'
                }
            })
        }
        employments = employments.map(employment => ({
            ...employment,
            text: generateEmploymentText(employment)
        }))

        const combined = [...shifts, ...shiftRequests]
        setOriginalShifts(combined)
        setShifts(combined)
        setEmployments(employments.sort((a, b) => a.order - b.order))
        const [filteredStaffReqs, hoursWithStaffReq] = getStaffReq(
            data.staff_requirements,
            data.operation_hours,
            shiftDate
        )
        setStaffRequirements(filteredStaffReqs)
        setHoursWithStaffReq(hoursWithStaffReq)
        setPredictedSales(data.customer_predicted_sales || data.predicted_sales)
        setStore(data.store)
        setStoreHour(data.operation_hours)
        if (!filteredStaffReqs.length) {
            setShowAlert(true)
        }
    }

    const resetShifts = () => {
        
        handleData(originalData)
    }

    const saveNotes = () => {
        setMemoLoading(true)
        if (dailyNotes?.id) {
            AxiosWithAuth.put(`/daily_notes/${dailyNotes?.id}`, {
                note: dailyNotes.note
            }).then(({ data }) => {
                setMemoLoading(false)
                setDailyNotes({ ...data, original: data.note })
                setSnackbarMessage('メモを保存しました')
                setSnackbarOpen(true)
            })
        } else {
            AxiosWithAuth.post('/daily_notes', {
                store_id: store.id,
                date: shiftDate,
                note: dailyNotes?.note
            }).then(({ data }) => {
                setMemoLoading(false)
                setDailyNotes({ ...data, original: data.note })
                setSnackbarMessage('メモを保存しました')
                setSnackbarOpen(true)
            })
        }
    }

    const removeUnloadHandler = () => {
        window.removeEventListener('beforeunload', onBeforeUnload, { capture: true })
    }

    const addFusokuUser = () => {
        let currentMax = 0
        employments.forEach(e => {
            if (e.user.name_last === '不足シフト') {
                currentMax = Math.max(currentMax, parseInt(e.user.name_first))
            }
        })
        if (currentMax >= 5) {
            setSnackbar({ message: '不足シフトは５つまで追加できます', severity: 'error' })
            return
        }
        const newEmployment = {
            id: 'fusoku' + (currentMax + 1),
            emp_cd: currentMax + 1,
            user: {
                name_first: `${currentMax + 1}`,
                name_last: '不足シフト'
            }
        }
        newEmployment.text = generateEmploymentText(newEmployment)
        setFusokuNumber(currentMax + 1)
        setEmployments([...employments, newEmployment])
    }

    useEffect(() => {
        if (changed) {
            window.addEventListener('beforeunload', onBeforeUnload, { capture: true })
        } else {
            removeUnloadHandler()
        }
        return () => {
            removeUnloadHandler()
        }
    }, [changed])

    useEffect(() => {
        if (modalStartTime.split(':')[1] % 15 > 0) {
            // if startTime doesn't end in 0, 15, 30, 45
            setStartError(true)
        } else {
            setStartError(false)
        }
        if (modalEndTime.split(':')[1] % 15 > 0) {
            setEndError(true)
        } else {
            setEndError(false)
        }
        if (modalBreakStartTime.split(':')[1] % 15 > 0) {
            setBreakStartError(true)
        } else {
            setBreakStartError(false)
        }
        if (modalBreakEndTime.split(':')[1] % 15 > 0) {
            setBreakEndError(true)
        } else {
            setBreakEndError(false)
        }
    }, [modalStartTime, modalEndTime, modalBreakStartTime, modalBreakEndTime])

    useEffect(() => {
        if (originalShifts) {
            const splitShifts = splitShiftsIntoBlocks(originalShifts)
            const splitBlockers = splitShiftsIntoBlocks(blockers)
            setNonConfirmedShifts(splitShifts.filter(shift => shift.status !== 'confirmed').concat(splitBlockers))
            if (isManager || canEditShiftFor[employmentId] || userInfo?.view_only) {
                setConfirmedShifts(splitShifts.filter(shift => shift.status === 'confirmed'))
            } else {
                setConfirmedShifts(splitShifts.filter(shift => shift.is_released || shift.row_number))
            }
        }
    }, [originalShifts, isManager, canEditShiftFor, userInfo])

    useEffect(() => {
        const confirmedShiftsToExclude = []
        let combinedShifts = nonConfirmedShifts.map(shift => {
            let overlappingShift = null
            confirmedShifts.forEach(confirmedShift => {
                if (
                    confirmedShift?.startDate.getTime() === shift.startDate.getTime() &&
                    confirmedShift?.employment_id === shift.employment_id
                ) {
                    overlappingShift = confirmedShift
                    confirmedShiftsToExclude.push(confirmedShift.id)
                }
            })
            return overlappingShift || shift
        })
        combinedShifts = combinedShifts.concat(
            confirmedShifts
                .filter(shift => !confirmedShiftsToExclude.includes(shift.id))
                .map(shift => ({ ...shift, notAvailable: true }))
        )
        setShifts(combinedShifts)
    }, [confirmedShifts, nonConfirmedShifts])

    useEffect(() => {
        if (shiftDate && employmentId) {
            AxiosWithAuth.get('/calendars/dailyShifts', {
                params: { employment_id: employmentId, date: shiftDate }
            }).then(res => {
                handleData(res.data)
            })
        }
    }, [shiftDate, employmentId])
    useEffect(() => {
        if (isManagerFor[employmentId] || canEditShiftFor[employmentId]) {
            AxiosWithAuth.get('/daily_note_templates', {
                params: { employment_id: employmentId }
            })
                .then(res => {
                    setTemplates(res.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [isManagerFor, canEditShiftFor, employmentId])

    useEffect(() => {
        if (isMobile()) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }, [])

    const isChecking = history.location.pathname.includes('check')

    const timeChunks = useMemo(() => {
        let open = 7
        let close = 27

        if (storeHour?.close_time && storeHour?.open_time) {
            const closing = storeHour?.close_time.split(':')
            if (parseInt(closing[1]) > 0) {
                close = parseInt(closing[0]) + 1
            } else {
                close = parseInt(closing[0])
            }
            open = parseInt(storeHour?.open_time.split(':')[0])
            if (close < open || storeHour.next_day_end) {
                close += 24
            }
        } else if (staffRequirements.length > 0) {
            const sortedRequirements = staffRequirements
                .filter(req => req['pred_sales'] > 0)
                .sort((a, b) => a.hour - b.hour)
            open = sortedRequirements[0]?.hour
            close = sortedRequirements.at(-1)?.hour + 1
        }

        let chunks = []
        const [year, month, day] = shiftDate.split('-')
        const shiftDay = new moment([year, month - 1, day])
        for (let i = open * 4 * 15; i < close * 60; i += 15) {
            const time = shiftDay.toDate()
            time.setHours(0)
            time.setMinutes(i)
            chunks.push(time)
        }
        return chunks
    }, [shiftDate, staffRequirements, storeHour])

    const Legend = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: desktop ? '1%' : '',
                    width: desktop ? '30%' : ''
                }}
            >
                <div className="legend-key">
                    <div
                        style={{
                            background: 'repeating-linear-gradient(to right, #BAC0E4, #BAC0E4 3px, #fff 3px, #fff 6px)',
                            width: '15px',
                            height: '4px',
                            marginRight: '3px'
                        }}
                    ></div>
                    <Typography sx={{ fontSize: 12, ml: 1 }}>希望時間</Typography>
                </div>
                <div className="legend-key">
                    <Circle fontSize="8px" color="confirmed" />
                    <Typography sx={{ fontSize: 12, ml: 1 }}>シフト確定</Typography>
                </div>
                <div className="legend-key">
                    <Circle fontSize="8px" color="blocked" />
                    <Typography sx={{ fontSize: 12, ml: 1 }}>他店ブロック</Typography>
                </div>
            </div>
        )
    }

    const NavigationButtons = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ width: desktop ? '30%' : '10%' }}
            >
                <IconButton onClick={moveToPreviousDay}>
                    <KeyboardArrowLeft />
                    </IconButton>
                <IconButton onClick={() => {setShowMonthlyView(true); setShiftDate('')}}>
                    <CalendarToday />
                </IconButton>
                <IconButton onClick={moveToNextDay}>
                    <KeyboardArrowRight />
                </IconButton>
            </Stack>
        )
    }

    const EditAndExportButtons = () => {
        return (
            <>
                {!editing && !userInfo?.view_only && (
                    <ButtonWithConfirmPrompt
                        component={
                            <IconButton color={editing ? 'paloBlue' : 'default'} data-testid="edit-button">
                                <EditOutlined />
                            </IconButton>
                        }
                        title="シフトの変更"
                        content="既にシフトを送信しています。変更しますか？"
                        confirmText="変更する"
                        handleConfirm={() => {
                            setEditing(true)
                            setIsOverwriting(true)
                        }}
                    />
                )}
                {isDataLoaded && <PdfBulkExportButton shiftDates={[shiftDate]} />}
            </>
        )
    }

    return (
        <>
            <Prompt
                when={changed}
                message="ページを切り替えると行った変更が保存されない可能性があります。よろしいですか。"
            />
            <div>
                <Dialog open={dialogue} data-testid="modal">
                    <Box display="flex" direction="row" justifyContent="space-between" alignItems={'center'}>
                        <DialogTitle>勤務時間</DialogTitle>
                        <DeleteOutline data-testid="modalDelete" onClick={handleModalDelete} sx={{ mr: '1rem' }} />
                    </Box>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', width: '16rem' }}>
                        <Stack spacing={1.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <InputTime
                                startValue={modalStartTime}
                                endValue={modalEndTime}
                                startOnChange={e => setModalStartTime(e.target.value)}
                                endOnChange={e => setModalEndTime(e.target.value)}
                                startError={startError}
                                endError={endError}
                                startTestId={modalStartTime}
                                endTestId={modalEndTime}
                                addBreak={true}
                                breakStartTime={modalBreakStartTime}
                                breakEndTime={modalBreakEndTime}
                                setBreakStartTime={e => setModalBreakStartTime(e.target.value)}
                                setBreakEndTime={e => setModalBreakEndTime(e.target.value)}
                                breakStartError={breakStartError}
                                breakEndError={breakEndError}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setDialogue(false)}>
                            キャンセル
                        </Button>
                        <Button
                            disabled={startError || endError || breakStartError || breakEndError}
                            onClick={() => {
                                setDialogue(false)
                                handleModalConfirm()
                            }}
                            autoFocus
                        >
                            確定する
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{ margin: desktop ? '1% 20%' : '' }}>
                    {userInfo?.view_only ? (
                        <TextField
                            size="small"
                            sx={{ border: 'none', mx: 'auto', width: '30%', display: 'block' }}
                            fullWidth
                            disabled
                            value={
                                JSON.parse(localStorage.selectedStoreViewOnly || '')?.seg_code +
                                ' - ' +
                                JSON.parse(localStorage.selectedStoreViewOnly || '')?.name
                            }
                        />
                    ) : null}
                </div>
                {showAlert && (
                    <Alert
                        sx={{ width: '80%', margin: '10px auto' }}
                        severity="warning"
                        onClose={() => setShowAlert(false)}
                    >
                        予測データ準備中・・・
                    </Alert>
                )}
                {desktop && (
                    <>
                        <Stack direction={'row'} justifyContent="space-between" sx={{ mx: '1rem', my: '1rem' }}>
                            <Box display='flex' alignItems='center' sx={{width: '50%'}}>
                                <Typography variant="h6">
                                    {formatCurrentDate()}
                                </Typography>
                                <NavigationButtons />                        
                            </Box>
                            <Box display='flex' alignItems='center' justifyContent='flex-end' sx={{width: '50%'}}>
                                {(isManagerFor[employmentId] ||
                                    canEditShiftFor[employmentId] ||
                                    userInfo?.view_only) && <EditAndExportButtons />}
                                {!collapsedRows ? <Button
                                        variant="text"
                                        startIcon={<CompressOutlined />}
                                        onClick={() => setCollapsedRows(true)}
                                        sx={{ mx: '.5rem' }}
                                    >
                                        行を圧縮
                                    </Button>
                                    :
                                    <Button
                                        variant="text"
                                        startIcon={<ExpandOutlined />}
                                        onClick={() => setCollapsedRows(false)}
                                        sx={{ mx: '.5rem' }}
                                    >
                                        行を展開
                                    </Button>
                                }
                            {(canEditShiftFor[employmentId] || isManagerFor[employmentId]) &&
                                (!isChecking || editing) &&
                                fusokuNumber < 5 && (
                                    <Button
                                        onClick={addFusokuUser}
                                        variant="text"
                                        startIcon={<AddOutlined />}
                                    >
                                        {'不足シフトの追加'}
                                    </Button>
                                )}
                            {(canEditShiftFor[employmentId] || isManagerFor[employmentId]) && (!isChecking || editing) && (
                                <>
                                    {!isMobile() && (
                                        <Button
                                            variant="text"
                                            startIcon={<AutorenewOutlined />}
                                            onClick={resetShifts}
                                        >
                                            リセット
                                        </Button>
                                    )}
                                    <Button
                                        onClick={handleShiftSubmit}
                                        variant="contained"
                                        color="paloBlue"
                                    >
                                        {isOverwriting ? '変更を保存する' : 'シフトを保存する'}
                                    </Button>
                                </>
                            )}
                            </Box>

                        </Stack>
                        <Stack direction={'column'} spacing={1} alignItems="center">
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <TextareaAutosize
                                    className="MuiOutlinedInput"
                                    minRows={5}
                                    maxRows={5}
                                    placeholder="メモ"
                                    style={{
                                        width: '93%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        resize: 'none'
                                    }}
                                    value={dailyNotes?.note || ''}
                                    onChange={e => {
                                        handleTextareaChange(e)
                                    }}
                                    disabled={!(isManagerFor[employmentId] || canEditShiftFor[employmentId])}
                                />
                                {(isManagerFor[employmentId] || canEditShiftFor[employmentId]) && (
                                    <div style={{ margin: '0.3rem', marginLeft: '-9rem', height: '2.5rem' }}>
                                        <IconButton
                                            aria-describedby={popoverId}
                                            onClick={e => setDailyNotePopoverAnchorEl(e.currentTarget)}
                                        >
                                            <AddCommentOutlined />
                                        </IconButton>
                                        <Popover
                                            open={dailyNotesPopoverOpen}
                                            anchorEl={dailyNotePopoverAnchorEl}
                                            onClose={() => setDailyNotePopoverAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column',
                                                    p: 1,
                                                    m: 1,
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1
                                                }}
                                            >
                                                {templates?.map(template => (
                                                    <Button
                                                        key={template.id}
                                                        variant="text"
                                                        style={{ color: 'black' }}
                                                        onClick={() => {
                                                            setDailyNotes({
                                                                ...dailyNotes,
                                                                note: (dailyNotes.note || '') + template.note + '\n'
                                                            })
                                                        }}
                                                    >
                                                        {template.note}
                                                    </Button>
                                                ))}
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        setEditTemplate(true)
                                                        setDailyNotePopoverAnchorEl(null)
                                                    }}
                                                >
                                                    テンプレートを編集
                                                </Button>
                                            </Box>
                                        </Popover>
                                        <LoadingButton
                                            onClick={saveNotes}
                                            variant="contained"
                                            color="paloBlue"
                                            loading={memoLoading}
                                            disabled={dailyNotes?.note === dailyNotes?.original}
                                        >
                                            保存する
                                        </LoadingButton>
                                    </div>
                                )}
                            </div>
                        </Stack>
                        <Stack direction={'row'} justifyContent="flex-end" sx={{ mx: '1rem', my: '1rem' }}>
                            <Legend />
                        </Stack>
                    </>
                )}
                {!desktop && (
                    <>
                        <Stack sx={{ my: '.5rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="h8" sx={{ marginLeft: '0.5rem' }}>
                                    {formatCurrentDate()}
                                </Typography>
                                <NavigationButtons />
                                <div>
                                    {(isManagerFor[employmentId] ||
                                        canEditShiftFor[employmentId] ||
                                        userInfo?.view_only) &&
                                        isChecking &&
                                        !editing && <EditAndExportButtons />}

                                    {(canEditShiftFor[employmentId] || isManagerFor[employmentId]) &&
                                        (!isChecking || editing) &&
                                        fusokuNumber < 5 && (
                                            <IconButton onClick={addFusokuUser}>
                                                <PersonAddAlt1 />
                                            </IconButton>
                                    )}
                                     {(canEditShiftFor[employmentId] || isManagerFor[employmentId]) && (!isChecking || editing) &&
                                        <IconButton onClick={resetShifts}>
                                            <AutorenewOutlined />
                                        </IconButton>
                                    }
                                    <ButtonWithConfirmPrompt
                                        component={
                                            <IconButton color={editing ? 'paloBlue' : 'default'}>
                                                <ChatBubbleOutline />
                                            </IconButton>
                                        }
                                        title="メモを入力/確認する"
                                        content={
                                            <TextareaAutosize
                                                className="MuiOutlinedInput"
                                                minRows={5}
                                                maxRows={5}
                                                placeholder="メモ"
                                                style={{
                                                    width: '95%',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    resize: 'none'
                                                }}
                                                value={dailyNotes?.note || ''}
                                                onChange={e => {
                                                    handleTextareaChange(e)
                                                }}
                                                disabled={
                                                    !(isManagerFor[employmentId] || canEditShiftFor[employmentId])
                                                }
                                            />
                                        }
                                        confirmTextDisabled={
                                            !(isManagerFor[employmentId] || canEditShiftFor[employmentId]) ||
                                            dailyNotes?.note === dailyNotes?.original
                                        }
                                        confirmText="変更する"
                                        handleConfirm={() => {
                                            saveNotes()
                                        }}
                                    />
                                </div>
                            </div>
                        </Stack>
                        <Divider />
                        <Legend />
                        <Divider />
                    </>
                )}
                { orientation === "landscape" || (desktop && timeChunks) ? (
                    <DesktopCalendar
                        timeChunks={timeChunks}
                        fullDayStaffed={fullDayStaffed}
                        checkIfFullyStaffed={checkIfFullyStaffed}
                        getStaffRequired={getStaffRequired}
                        staffRequirements={staffRequirements}
                        predictedSales={predictedSales}
                        employments={employments}
                        shifts={shifts.concat(blockers)}
                        nonConfirmedShifts={nonConfirmedShifts}
                        computeColor={computeColor}
                        isManagerFor={isManagerFor}
                        canEditShiftFor={canEditShiftFor}
                        employmentId={employmentId}
                        handleCalendarClick={handleCalendarClick}
                        handleLongPress={handleLongPress}
                        hours={storeHour}
                        collapsedRows={collapsedRows}
                        viewOnly={userInfo?.view_only}
                    />
                ) : (
                    <MobileCalendar
                        timeChunks={timeChunks}
                        fullDayStaffed={fullDayStaffed}
                        checkIfFullyStaffed={checkIfFullyStaffed}
                        getStaffRequired={getStaffRequired}
                        staffRequirements={staffRequirements}
                        predictedSales={predictedSales}
                        employments={employments}
                        shifts={shifts.concat(blockers)}
                        nonConfirmedShifts={nonConfirmedShifts}
                        computeColor={computeColor}
                        isManagerFor={isManagerFor}
                        userInfo={userInfo}
                        canEditShiftFor={canEditShiftFor}
                        employmentId={employmentId}
                        handleCalendarClick={handleCalendarClick}
                        handleLongPress={handleLongPress}
                    />
                )}
                {!desktop && (canEditShiftFor[employmentId] || isManagerFor[employmentId]) && (!isChecking || editing) && (
                    <Button
                        onClick={handleShiftSubmit}
                        variant="contained"
                        color="paloBlue"
                        style={{ width: '-webkit-fill-available', margin: '1rem 1rem 2rem 1rem' }}
                    >
                        {isOverwriting ? '変更を保存する' : 'シフトを保存する'}
                    </Button>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => {
                        setSnackbarMessage(null)
                        setSnackbarOpen(false)
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => {
                            setSnackbarOpen(false)
                            setSnackbarMessage(null)
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        {snackbarMessage || 'シフトを保存しました。'}
                    </Alert>
                </Snackbar>
            </div>
            <EditDailyNoteTemplateDrawer
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                editTemplate={editTemplate}
                setEditTemplate={setEditTemplate}
                templates={templates}
                setTemplates={setTemplates}
            />
            {submitShiftDialog && (
                <Dialog open={submitShiftDialog}>
                    <DialogContent>
                        <SubmitShift
                            shiftDate={moment(shiftDate).format('YYYY-MM-DD')}
                            submitFor={employmentId}
                            setSubmitShiftDialog={setSubmitShiftDialog}
                            setAllShifts={() => null}
                            store={store}
                            setSnackbar={setSnackbar}
                            suggestedStartEnd={suggestedStartEnd}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

export const computeColor = (authorized, shift) => {
    const color =
        !authorized && !shift.is_released
            ? null
            : shift.status === 'confirmed' && (String(shift?.id)?.includes('fusoku') || shift?.row_number > 0)
            ? FUSOKU
            : shift.status === 'confirmed'
            ? CONFIRMED
            : shift.status === 'submitted'
            ? '#fff'
            : shift.status === 'blocked'
            ? BLOCKED
            : null
    return color
}

export default CheckShiftDay
