import React, { useState } from 'react';
import {
    Typography,
    Button,
    Box,
    Modal,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput
} from '@mui/material'
import { SentimentNeutral, SentimentSatisfiedAltOutlined, SentimentVeryDissatisfied } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { AxiosWithAuth } from '../../Utilities/authenticationService'

const FeedbackModal = ({ setSentFeedback, sentFeedback }) => {
    const { calendarYearMonth, employmentId } = useParams()
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)
    const [userRating, setUserRating] = useState('')
    const [userComment, setUserComment] = useState('')
    const [commentError, setCommentError] = useState('')
    return (
        <>
            <Button
                onClick={() => setShowFeedbackModal(true)}
                variant="text"
                startIcon={<SentimentSatisfiedAltOutlined />}
                sx={{ color: 'black', fontSize: '12px' }}
                disabled={sentFeedback}
            >
                {sentFeedback ? 'シフト評価済み' : 'シフトを評価'}
            </Button>
            <Modal
                open={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
            >
                <div
                    style={{

                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 350,
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 4,
                        height: 350,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h6" component="h2" sx={{ p: 2 }}>
                        シフトの評価
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'red', textAlign: 'center' }}>
                        {commentError}
                    </Typography>
                    <Box display='flex' width='100%' justifyContent='space-evenly'>
                        <IconButton
                            onClick={() => setUserRating('good')}
                            sx={{ color: userRating === 'good' ? 'green' : 'lightgray' }}
                        >
                            <SentimentSatisfiedAltOutlined style={{ fontSize: '4rem' }}/>
                        </IconButton>
                        <IconButton
                            onClick={() => setUserRating('neutral')}
                            sx={{ color: userRating === 'neutral' ? 'green' : 'lightgray' }}
                        >
                            <SentimentNeutral style={{ fontSize: '4rem' }}/>
                        </IconButton>
                        <IconButton
                            onClick={() => setUserRating('bad')}
                            sx={{ color: userRating === 'bad' ? 'green' : 'lightgray' }}
                        >
                            <SentimentVeryDissatisfied style={{ fontSize: '4rem' }}/>
                        </IconButton>
                    </Box>
                    <Box display='flex' justifyContent='space-evenly'>
                        <FormControl sx={{ width: '90%'}}>
                            <InputLabel htmlFor="component-outlined">コメント</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                value={userComment}
                                style={{ height: '6rem' }}
                                onChange={e => setUserComment(e.target.value)}
                                label="コメント"
                            />
                        </FormControl>
                    </Box>
                    <Box display='flex' width='100%' justifyContent='flex-end' >
                        <Button onClick={() => setShowFeedbackModal(false)}>キャンセル</Button>
                        <Button
                            onClick={() => {
                                if (!userRating || !userComment) {
                                    setCommentError('評価とコメントを入力してください')
                                    return
                                }
                                AxiosWithAuth.post('/feedbacks', {
                                    employment_id: employmentId,
                                    rating: userRating,
                                    comment: userComment,
                                    year_month: calendarYearMonth
                                }).then(res => {
                                    if (res.status === 201) {
                                        setSentFeedback(true)
                                        setShowFeedbackModal(false)
                                    }
                                })
                            }}
                        >
                            送信する
                        </Button>
                    </Box>
                </div>
            </Modal>
        </>
    )
}

export default FeedbackModal;