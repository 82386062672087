import React, { useState } from 'react'
import { Box, TextField, Stack, Button, InputAdornment, IconButton, Modal, Typography, Divider } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { authenticationService, AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'

const EditPassword = ({ open, handleClose, propsId, handleReturn, emp_cd }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState({ first: '', second: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState({})
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightgray',
        boxShadow: 24,
        pt: 2,
        pb: 3,
        borderRadius: '10px'
      };

    return (
        <Modal open={open}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" sx={{ mb: '1rem', px: 3 }}>
                    パスワードの変更
                </Typography>
                <Divider />
                <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                    <TextField
                        label="新しいパスワード"
                        placeholder={'例：000abcdABCD'}
                        helperText={
                            errors.password || '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                        }
                        InputLabelProps={{ shrink: true }}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setShowPassword(!showPassword)
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        value={newValue.first}
                        onChange={e => setNewValue({ ...newValue, first: e.target.value })}
                        error={errors.password ? true : false}
                    />
                    <TextField
                        label="新しいパスワード"
                        placeholder={'例：000abcdABCD'}
                        helperText={
                            errors.password_confirmation ||
                            '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                        }
                        InputLabelProps={{ shrink: true }}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setShowPassword(!showPassword)
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        value={newValue.second}
                        onChange={e => setNewValue({ ...newValue, second: e.target.value })}
                        error={errors.password_confirmation ? true : false}
                        sx={{ mt: '1rem' }}
                    />
                </Stack>
                <Divider />
                <Stack direction='row' spacing={2} sx={{ justifyContent: 'flex-end', mr: 2, mt: 2 }}>
                    <ButtonWithConfirmPrompt
                        component={<Button>変更する</Button>}
                        title="パスワードの変更"
                        content="パスワードを変更してもよろしいですか？"
                        confirmText="変更する"
                        handleConfirm={() => {
                            AxiosWithAuth.put('/users/' + (userId || propsId), {
                                user: { password: newValue.first, password_confirmation: newValue.second }
                            })
                                .then(res => {
                                    if (res.status == 200) {
                                        authenticationService.login(emp_cd, newValue.first).then(res => {
                                            if (res.status === 200) {
                                                handleReturn()
                                            }
                                        })
                                    }
                                })
                                .catch(error => {
                                    const errors = {}
                                    for (const [key, value] of Object.entries(error?.response?.data)) {
                                        errors[key] = value.join(' ')
                                    }
                                    setErrors(errors)
                                })
                        }}
                    />
                    <Button onClick={handleClose}>キャンセル</Button>
                </Stack>
            </Box>
        </Modal>
    )
}
export default EditPassword
