import React from 'react'
import { MenuItem, Select } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useStickyState } from '../Utilities/useStickyState'
import { isMobile } from '../Utilities/isMobile'
import moment from 'moment'
moment.locale('ja')

const SelectedYearMonth = ({ disabled }) => {
    const currentYearMonth = moment()
    const [selectedYearMonth, setSelectedYearMonth] = useStickyState(currentYearMonth.format('YYYY-M'), 'frederickSelectedYearMonth')
    const menuItems = [
        { value: currentYearMonth.format('YYYY-M'), label: currentYearMonth.format('M') },
        { value: currentYearMonth.clone().add(1, 'month').format('YYYY-M'), label: currentYearMonth.clone().add(1, 'month').format('M') },
        { value: currentYearMonth.clone().add(2, 'months').format('YYYY-M'), label: currentYearMonth.clone().add(2, 'months').format('M') }
    ];
    const history = useHistory()
    const handleChange = e => {
        setSelectedYearMonth(e.target.value)
        history.replace(history.location.pathname.replace(selectedYearMonth, e.target.value))
    }
    
    return (
        <div
            style={{
                margin: '.5rem',
                background: 'rgba(0, 0, 0, .04)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: isMobile() ? '30%': '6rem'
            }}
        >
            <Select
                disabled={disabled}
                size="small"
                sx={{ border: 'none', mx: 'auto' }}
                fullWidth
                value={selectedYearMonth}
                onChange={handleChange}
            >
                {menuItems.map(item => (
                    <MenuItem
                        value={item.value}
                        key={item.value}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {item.label}月
                    </MenuItem>
                ))}
            </Select>
        </div>
    )

}

export default SelectedYearMonth
