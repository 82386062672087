import React, { useEffect, useState } from 'react'
import {
    Typography,
    Stack,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    MenuItem,
    Snackbar,
    Alert,
    Box,
    IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import { isMobile } from '../../Utilities/isMobile'
import { InputTime } from '../../Utilities/shiftUtils'

const weekdayOptions = [
    { label: '月曜日', value: 'monday' },
    { label: '火曜日', value: 'tuesday' },
    { label: '水曜日', value: 'wednesday' },
    { label: '木曜日', value: 'thursday' },
    { label: '金曜日', value: 'friday' },
    { label: '土曜日', value: 'saturday' },
    { label: '日曜日', value: 'sunday' }
]

const RecurringShifts = ({ setShowRecurringShiftModal, submitFor, viewRecurring=false }) => {
    console.log(submitFor, viewRecurring)
    const [dialogue, setDialogue] = useState(false)
    const [editted, setEditted] = useState(false)
    const [recurringShifts, setReccuringShifts] = useState([])
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false)

    useEffect(async () => {
        await AxiosWithAuth.get('/recurring_shifts', {
            params: { employment_id: submitFor }
        }).then(res => {
            let rsArr = []
            res.data.forEach(shift => {
                let currTimeRange = shift.start_time + '-' + shift.end_time
                let added = false
                rsArr.map(s => {
                    if (s.timeRange === currTimeRange) {
                        s.daysArr.push(shift.day)
                        added = true
                    }
                })
                if (!added) {
                    rsArr.push({
                        timeRange: currTimeRange,
                        daysArr: [shift.day],
                        startError: false,
                        endError: false,
                        dayError: false
                    })
                }
            })
            setReccuringShifts(rsArr)
        })
    }, [submitFor])
    const handleClose = () => {
        viewRecurring && localStorage.setItem('rsUpdated', true)
        setShowRecurringShiftModal(false)
    }
    const handleSubmit = async () => {
        const responses = []
        if (hasOverlaps(recurringShifts)) {
            setDialogue(false)
            setErrorSnackbarOpen(true)
            return
        }
        if (!editted) {
            handleClose()
        }
        // delete original recurring shifts
        await AxiosWithAuth.delete('/recurringShiftsDeleteAll/' + submitFor)
        // total recurring shifts
        let totalRecurringShiftCount = 0
        recurringShifts.forEach(shift => {
            shift.daysArr.forEach(() => {
                totalRecurringShiftCount++
            })
        })
        if (!totalRecurringShiftCount) {
            handleClose()
        }
        // add new recurring shifts
        recurringShifts.forEach(shift => {
            shift.daysArr.forEach(async day => {
                const [start_time, end_time] = shift.timeRange.split('-')
                const res = await AxiosWithAuth.post('/recurring_shifts', {
                    employment_id: submitFor,
                    day,
                    start_time,
                    end_time
                })
                responses.push(res)
                
                if (responses.length === totalRecurringShiftCount) {
                    handleClose()
                }
            })
        })
    }
    return (
        <>
            <Stack sx={{ mx: isMobile() ? '.5rem' : 0 }}>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        固定シフトの設定
                    </Typography>
                    <IconButton onClick={() => setShowRecurringShiftModal(false)}>
                        <Close />
                    </IconButton>
                </Stack>
                {!recurringShifts.length ? (
                    <Typography variant="subtitle1">固定シフトがありません</Typography>
                ) : (recurringShifts.map(({ timeRange, daysArr, startError, endError, dayError }, idx) => (
                        <Stack key={timeRange} spacing={2} sx={{ margin: '1rem 0' }}>
                            {idx > 0 && <Divider orientation="horizontal" flexItem />}
                            <ButtonWithConfirmPrompt
                                component={<DeleteOutlined color="grey" sx={{ fill: 'grey' }} />}
                                title="固定シフトの削除"
                                content="本当に削除しますか？"
                                confirmText="削除する"
                                handleConfirm={() => {
                                    const original = [...recurringShifts]
                                    original.splice(idx, 1)
                                    setReccuringShifts(original)
                                    setEditted(true)
                                }}
                            />
                            <InputTime
                                startValue={timeRange.split('-')[0]}
                                endValue={timeRange.split('-')[1]}
                                startError={startError}
                                endError={endError}
                                startOnChange={e => {
                                    const original = [...recurringShifts]
                                    const rsToEdit = { ...original[idx] }
                                    rsToEdit.timeRange = e.target.value + '-' + timeRange.split('-')[1]
                                    original.splice(idx, 1, rsToEdit)
                                    setReccuringShifts(original)
                                    setEditted(true)
                                }}
                                endOnChange={e => {
                                    const original = [...recurringShifts]
                                    const rsToEdit = { ...original[idx] }
                                    rsToEdit.timeRange = timeRange.split('-')[0] + '-' + e.target.value
                                    original.splice(idx, 1, rsToEdit)
                                    setReccuringShifts(original)
                                    setEditted(true)
                                }}
                                startTestId="start-time"
                                endTestId="end-time"
                            />
                            <TextField
                                select
                                label="曜日"
                                placeholder="選択してください"
                                SelectProps={{
                                    multiple: true,
                                    value: daysArr || [],
                                    onChange: e => {
                                        const original = [...recurringShifts]
                                        const rsToEdit = { ...original[idx] }
                                        rsToEdit.daysArr = e.target.value
                                        original.splice(idx, 1, rsToEdit)
                                        setReccuringShifts(original)
                                        setEditted(true)
                                    }
                                }}
                                sx={{ marginTop: '.5rem' }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ 'data-testid': 'dow' }}
                                error={dayError}
                                helperText={dayError ? '曜日を選択してください。' : null}
                            >
                                {weekdayOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    ))
                )}
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <Button
                    onClick={() => {
                        const original = [...recurringShifts]
                        original.push({
                            timeRange: '09:00-17:00',
                            daysArr: [],
                            startError: false,
                            endError: false,
                            dayError: false
                        })
                        setReccuringShifts(original)
                    }}
                    variant="outlined"
                    color="paloBlue"
                    className="main-button"
                    style={{ width: '-webkit-fill-available', margin: isMobile() ? '0 1rem 1rem 1rem' : '0 .5rem 1rem 0' }}
                    startIcon={<AddOutlined />}
                    data-testid="add-recurring-shift-btn"
                >
                    固定シフトの時間帯を追加する
                </Button>
                <Button
                    onClick={() => (handleValidate(recurringShifts, setReccuringShifts) ? setDialogue(true) : null)}
                    variant="contained"
                    color="paloBlue"
                    className="main-button"
                    disabled={!editted}
                    style={{ width: '-webkit-fill-available', margin: isMobile() ? '0 1rem 2rem 1rem' : '0 .5rem 0 0' }}
                >
                    保存する
                </Button>
                <Dialog open={dialogue}>
                    <DialogTitle>固定シフトの登録</DialogTitle>
                    <DialogContent>固定シフトとして登録します。よろしいですか。</DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setDialogue(false)}>
                            キャンセル
                        </Button>
                        <Button onClick={handleSubmit} autoFocus>
                            登録する
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={errorSnackbarOpen}
                    severity="error"
                    onClose={() => setErrorSnackbarOpen(false)}
                >
                    <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
                        固定シフトが重複しています
                    </Alert>
                </Snackbar>
            </Box>
        </>
    )
}

export const handleValidate = (recurringShifts, setReccuringShifts) => {
    let issue = false
    recurringShifts.forEach(({ timeRange, daysArr }, idx) => {
        const start_time = timeRange.split('-')[0]
        const end_time = timeRange.split('-')[1]
        if (start_time.split(':')[1] % 15 > 0) {
            const original = [...recurringShifts]
            original[idx].startError = true
            setReccuringShifts(original)
            issue = true
        }
        if (end_time.split(':')[1] % 15 > 0) {
            const original = [...recurringShifts]
            original[idx].endError = true
            setReccuringShifts(original)
            issue = true
        }
        if (daysArr.length === 0) {
            const original = [...recurringShifts]
            original[idx].dayError = true
            setReccuringShifts(original)
            issue = true
        }
    })
    if (issue) {
        return false
    }
    return true
}

export const hasOverlaps = recurringShifts => {
    let overlapping = false
    let arr = []
    let keys = 0
    recurringShifts.forEach(({ timeRange, daysArr }) => {
        daysArr.forEach(day => {
            arr.push(`${keys}-${timeRange}-${day}`)
            keys++
        })
    })
    arr.forEach(val => {
        const [key, start_time, end_time, day] = val.split('-')
        const overlaps = arr.filter(k => {
            const [key2, start_time2, end_time2, day2] = k.split('-')
            return key !== key2 && day === day2 && end_time >= start_time2 && start_time <= end_time2
        })
        if (overlaps.length > 0) {
            overlapping = true
        }
    })
    return overlapping
}

export default RecurringShifts
