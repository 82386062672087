import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    MenuItem,
    ListItemIcon,
    Select,
    Box,
    Alert,
    CircularProgress,
    Table,
    TableCell,
    TableRow,
    TableHead,
    IconButton
} from '@mui/material'
import { KeyboardArrowRight, LocationOnOutlined } from '@mui/icons-material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { PATHS } from '../../constants'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { isMobile } from '../../Utilities/isMobile'

const AdminUserManagementList = ({ history }) => {
    const { segCode } = useParams()
    const [employments, setEmployments] = useState()
    const [selectedStore, setSelectedStore] = useState({})
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        AxiosWithAuth.get(`/stores/${segCode}`)
            .then(res => {
                setSelectedStore(res.data)
            })
            .catch(err => {
                console.log(err)
            })

        AxiosWithAuth.get(`/employments/store/${segCode}`)
            .then(res => {
                setEmployments(res?.data.sort((a, b) => a.order - b.order))
            })
            .catch(() => {
                setMessage('エラーが発生しました。')
            })
            .finally(() => setLoading(false))
    }, [segCode])

    return (
        <PageWithTitleLayout title="ユーザー情報" message={message} setMessage={setMessage} disableStoreSelect>
            <div
                style={{
                    margin: isMobile() ? '' : '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {history.location.pathname.includes('deleted') && (
                    <Alert sx={{ mx: '1rem' }} severity="success">
                        ユーザー{history.location.pathname.split(':')[1]}
                        を削除しました。
                    </Alert>
                )}
                {!loading && selectedStore && (
                    <div
                        style={{
                            margin: '.5rem auto',
                            background: 'rgba(0, 0, 0, .04)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: isMobile() ? '95%' : '620px'
                        }}
                    >
                        <Select
                            disabled={true}
                            size="small"
                            sx={{ border: 'none', mx: 'auto' }}
                            fullWidth
                            value={selectedStore?.id}
                        >
                            <MenuItem
                                value={selectedStore?.id}
                                sx={{
                                    background: 'rgba(0, 0, 0, .04)'
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                                        <LocationOnOutlined />
                                    </ListItemIcon>
                                    {selectedStore?.name}
                                </div>
                            </MenuItem>
                        </Select>
                    </div>
                )}
                <Box className="shrinkable">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        employments.length > 0 && (
                            <Table sx={{ width: isMobile() ? '101%' : '45em', marginTop: '1rem' }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>氏名</TableCell>
                                        <TableCell>社員番号</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {employments.map(emp => (
                                    <TableRow key={emp.id}>
                                        <TableCell>
                                            {emp.user.name_last + (emp.user.name_middle || '') + emp.user.name_first}
                                        </TableCell>
                                        <TableCell>{emp.user.emp_cd}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() =>
                                                    history.push(
                                                        `${PATHS.userManagementStores}admin/${emp.id}/${emp.user.id}`
                                                    )
                                                }
                                            >
                                                <KeyboardArrowRight />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        )
                    )}
                </Box>
            </div>
        </PageWithTitleLayout>
    )
}

export default AdminUserManagementList
